import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {  editPm, PM, removePM } from '../Model/pm.model';
import { ExportService } from '../services/export.service';
import { PMService } from '../services/pm.service';
declare var $:any
@Component({
  selector: 'app-project-manager',
  templateUrl: './project-manager.component.html',
  styleUrls: ['./project-manager.component.css']
})
export class ProjectManagerComponent implements OnInit {
  PmData:Array<any>=new Array();
  createPMmodel:PM=new PM();
  EditPmModel:editPm=new editPm();
  removepmModel:removePM=new removePM();
  isAdmin:boolean=false
  constructor(private __PmService:PMService, private Toastr:ToastrService , private exportService:ExportService) { }

  ngOnInit() {
    if(localStorage.getItem("role") != null && localStorage.getItem("role")=='Admin'){
      this.isAdmin=true
    }
   this.__PmService.getPMData().subscribe(res=>{
     this.PmData=res.body.projectManager
   }) 
   $("#phone").keyup(function() {
    $("#phone").val(this.value.match(/[0-9]*/));
});
  }
  createPm(){
    this.__PmService.CreatePMProfile(this.createPMmodel).subscribe(res=>{
      $("#createpm").modal("hide");
      this.Toastr.success("Successfully Created!!!");
      this.ngOnInit();
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  
  }
  closemodal() {
    $('#createpm').on('hidden.bs.modal',
      function () {
        $('#createpm form')[0].reset();
      });
  }
  selectedPmdata(data){
    this.EditPmModel=data
    this.removepmModel.id=data.id

  }
  updatePM(){
    this.__PmService.editPM(this.EditPmModel).subscribe(res=>{
      $("#updatePMmodal").modal("hide");
      this.Toastr.success("Successfully Updated!!!");
      this.ngOnInit();
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
   
  }
  removePM(){
    this.__PmService.deletePm(this.removepmModel).subscribe(res=>{

      $("#delete").modal("hide");
      this.Toastr.success("Successfully Deleted!!!");
      this.ngOnInit();
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  }
  export() {
    this.exportService.exportExcel(this.PmData, 'PM Excel');
  
}
}
