import { Component,EventEmitter, OnInit } from '@angular/core';
import { ClinicService } from "../services/clinic.service";
import {  Clinic, updateclinic} from "../Model/clinic.model";
import { LabsService } from '../services/labs.service';
import { ToastrService } from 'ngx-toastr';
import { GenericServiceService } from "../services/generic-service.service";
import { ExportService } from '../services/export.service';

declare var $: any;
@Component({
  selector: 'app-clinic',
  templateUrl: './clinic.component.html',
  styleUrls: ['./clinic.component.css']
})
export class ClinicComponent implements OnInit {
  headerTitle = new EventEmitter<string>();
isAdmin:boolean=false
DTC:boolean=false
TTAclinicdata:Array<any>= new Array();
createTTAClinic:Clinic=new Clinic()
updateclinicModel:updateclinic=new updateclinic();
TehsilData:Array<any>= new Array();
districtdata:Array<any>= new Array();
labdata:Array<any>= new Array();
districtdefalutdata:Array<any>= new Array();
tehsildefdata : Array<any> = new Array();
TehsildefData: Array<any> = new Array();
  constructor(private exportService:ExportService, private clinicsdata:ClinicService, private generic:GenericServiceService,private disttehsil:LabsService, private Toastr:ToastrService) { }

  ngOnInit() {
    this.headerTitle.emit("DR-TB Clinic");
    if(localStorage.getItem("role") != null && localStorage.getItem("role")=='Admin'){
      this.isAdmin=true
      this.disttehsil.getTTAdistrict().subscribe(res=>{
        this.districtdefalutdata=res.body.districts
        var ActiveDistrictModel = [];
        this.districtdefalutdata.forEach(function (value) {
        if(value.isActive==true)
        {
         ActiveDistrictModel.push(value);
        }
     },
     this.districtdata=ActiveDistrictModel
     
     
  
     );     
     console.log(this.districtdata);

      },
      error=>{
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      })
    //   this.clinicsdata.getTTAclinicData(0).subscribe(res=>{
    //        this.TTAclinicdata=res.body.clinics
    //     },
    //     error=>{
    //        this.Toastr.error("Something went on the server side !!!");
    //       console.log(error);
    //     })
    }
    if(localStorage.getItem("role") != null && localStorage.getItem("role")=='DTC'){
      this.DTC=true
      this.generic.dtc().subscribe(data=>{ 
        this.districtdefalutdata=data.body.dtc
        var ActiveDistrictModel = [];
        this.districtdefalutdata.forEach(function (value) {
        if(value.isActive==true)
        {
         ActiveDistrictModel.push(value);
        }
     },
     this.districtdata=ActiveDistrictModel
     );     
      })
       this.generic.getTehsilData(localStorage.getItem("DTCDISTRICTID")).subscribe(data=>{
      this.tehsildefdata=data.body.tehsils
      var ActiveDistrictModel = [];
      this.tehsildefdata.forEach(function (value) {
        if (value.isActive == true) {
          ActiveDistrictModel.push(value);
        }
      },
        this.TehsilData = ActiveDistrictModel
      );
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    })
    // this.clinicsdata.getTTAclinicData(localStorage.getItem("DTCDISTRICTID")).subscribe(res=>{
    //   this.TTAclinicdata=res.body.clinics
    // },
    // error=>{
    //   this.Toastr.error("Something went on the server side !!!");
    //   console.log(error);
    // });
    }
    
   
   
  }
  ShowSelecteddistrictData(id){
    this.disttehsil.getTTAtehsil(id).subscribe(data=>{
      this.TehsildefData=data.body.tehsils
      var ActiveDistrictModel = [];
      this.TehsildefData.forEach(function (value) {
      if(value.isActive==true)
      {
       ActiveDistrictModel.push(value);
      }
   },
   this.TehsilData=ActiveDistrictModel
   

   );     
    },
     error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    }
     )
    // this.clinicsdata.getTTAclinicData(id).subscribe(res=>{
    //   this.TTAclinicdata=res.body.clinics
    // },
    // error=>{
    //   this.Toastr.error("Something went on the server side !!!");
    //   console.log(error);
    // });
  }

  ShowSelectedtehsilData(id){
    this.clinicsdata.getTTAclinicDataontehsil(id).subscribe(data=>{
      this.TTAclinicdata=data.body.clinics  
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }
CreateClinic(){
  this.clinicsdata.TTAcreateclicic(this.createTTAClinic).subscribe(data=>{
   
    this.Toastr.success("Successfully Created !!!");
    this.ngOnInit();
    $("#createclinic").modal("hide");
    this.createTTAClinic.address='',
    this.createTTAClinic.contactNo='',
    this.createTTAClinic.doctorName='',
    this.createTTAClinic.clinicName=''
  
  },
  error=>{
    
    this.Toastr.error("Something went on the server side !!!");
    this.ngOnInit();
    console.log(error);
  })
}
closeClinicModal(){
  $('#createclinic').on('hidden.bs.modal',
  function () {
   $('#createclinic form')[0].reset();
   });
}
SelectedTTADetail(data){
  this.updateclinicModel=data
}
updateclinics(){
  this.clinicsdata.updateTTAclinic(this.updateclinicModel).subscribe(data=>{
    this.Toastr.success("Successfully Created !!!");
    this.ngOnInit();
    $("#updateclinic").modal("hide");
  },
  error=>{
    
    this.Toastr.error("Something went on the server side !!!");
    this.ngOnInit();
    console.log(error);
  })
}
export() {
  this.exportService.exportExcel(this.TTAclinicdata, 'Clinic Excel');

}
}
