import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient,HttpParams } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PatientstatusService {
  constructor(private _http:HttpClient,private router:Router) { }

  GetPatientStatus():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetPatientStatus";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }


  CreatePatientStatus(PatientStatus:string):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/CreatePatientStatus";
    let data = {patientStatus:PatientStatus}
    return this._http.post(FullUrl,data,requestOptions);
  }
  

  UpdatePatientStatus(id: number,Status: string,isActive:any):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/UpdatePatientStatus";
    let data = {id:id,patientStatus:Status,isActive:isActive}
    return this._http.post(FullUrl,data,requestOptions);
  }
}
