import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AllpatientsService {

 // baseUrl:string="http://patientrefferals-api-dev.asd.org.pk";
  constructor(private _http:HttpClient,private router:Router) { }

  getAllPatients():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/Patient/GetAllPatients?patientStatus="+0;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }

  GetAllTehsils():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetAllTehsils?districtId="+0;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }

  GetHealthFacility(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/HealthFacility/GetHealthFacility?tehsilId="+data;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }


  getPatientsDetailsByID(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/Patient/GetPatientDetailsById?patientId="+data;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }

  Update(
    // patientName,
    // fatherHusbandName,
    // genderId,
    // cnic,
    // phone,
    // tbNumber,
    // drtbnumber,
    // houseHoldsScreend,
    // cxrScreened,
    // sampleNumber,
// address,
// tehsilId,


    // patientId,
    data
    ):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patient/UpdatePatientDetails";
// let data={
//   patientName:patientName,
//   fatherHusbandName:fatherHusbandName,
//   genderId:genderId,
//   cnic:cnic,
//   phone:phone,
//   tbNumber:tbNumber,
//   drtbnumber:drtbnumber,
//   houseHoldsScreend:houseHoldsScreend,
//   cxrScreened:cxrScreened,
//   sampleNumber:sampleNumber,

//   patientId:patientId,

// }
    return this._http.post(FullUrl,data,requestOptions);
  }
  UpdateGeneExpert(
    // patientName,
    // fatherHusbandName,
    // genderId,
    // cnic,
    // phone,
    // tbNumber,
    // drtbnumber,
    // houseHoldsScreend,
    // cxrScreened,
    // sampleNumber,
// address,
// tehsilId,


    // patientId,
    data
    ):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patient/UpdateGeneExpertPatientDetails";
// let data={
//   patientName:patientName,
//   fatherHusbandName:fatherHusbandName,
//   genderId:genderId,
//   cnic:cnic,
//   phone:phone,
//   tbNumber:tbNumber,
//   drtbnumber:drtbnumber,
//   houseHoldsScreend:houseHoldsScreend,
//   cxrScreened:cxrScreened,
//   sampleNumber:sampleNumber,

//   patientId:patientId,

// }
    return this._http.post(FullUrl,data,requestOptions);
  }
  getAllpatients(data):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patient/GetAllPatients";

    return this._http.post(FullUrl,data,requestOptions);
  }
  getRRoption():Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    let FullUrl = localStorage.getItem('BaseUrl')+"​/api/Patient/GetRRValueOptions";

    return this._http.get(FullUrl,requestOptions);
  }
  getSaverity():Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    let FullUrl = localStorage.getItem('BaseUrl')+"​​/api/Patient/GetSampleResultSaverity";

    return this._http.get(FullUrl,requestOptions);
  }
  getRifam():Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    let FullUrl = localStorage.getItem('BaseUrl')+"​​/api/Patient/GetRifampicinOptions";

    return this._http.get(FullUrl,requestOptions);
  }

  // gene expert tab data api0
  getGEP():Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patient/GetAllOtherGenePatients";
console.log('requesting',FullUrl)
    return this._http.get(FullUrl,requestOptions);
  }
}
