export class Dtc {
    name:any
    phone:number
    gender:any
    districtId:any

}
export class updateDtc {
    name:any
    phone:number
    gender:any
    dtcId:any
    isActive:any
}
