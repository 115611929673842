import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardServiceService {

  constructor(private _http:HttpClient,private router:Router) { }

  getTTADashboardData(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/TbAssistant/GetDashboard";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.post(FullUrl,data,requestOptions );
  }

  // getDashboardPatients(data):Observable<any>{

  //   let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patient/GetTTAPatients?patientStatus";
  //   const headerDict = {
  //     'Access-Control-Allow-Origin':'*',
  //     "Authorization": "Bearer " + localStorage.getItem('access_token'),
  //     'Accept': 'application/json'
  //   }
  //   const requestOptions = {
  //     headers: new HttpHeaders(headerDict),
  //   };

  //   return this._http.get(FullUrl,data,requestOptions );
  // }
  getDashboardPatients(data):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patient/GetAllPatients";

    return this._http.post(FullUrl,data,requestOptions);
  }
  getDashboardPatientsOverall(data,start,end,filterstate):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let FullUrl='';
    if(filterstate.FilterState=="Filter")
    {
FullUrl = localStorage.getItem('BaseUrl')+(localStorage.getItem("userType")=="Admin"?("/api/Patient/GetAllPatientsOverviewFilter?patientStatus="+data+"&start="+start+"&end="+end+"&district="+filterstate.District+"&tehsil="+filterstate.Tehsil+"&gender="+filterstate.Gender):("/api/Patient/GetAllPatientsOverviewDTCFilter?patientStatus="+data+"&start="+start+"&end="+end+"&district="+filterstate.District+"&tehsil="+filterstate.Tehsil+"&gender="+filterstate.Gender));

    }
else if(filterstate.FilterState=="Dropdown")
    {
      FullUrl = localStorage.getItem('BaseUrl')+(localStorage.getItem("userType")=="Admin"?("/api/Patient/GetAllPatientsOverview?patientStatus="+data+"&start="+start+"&end="+end):("/api/Patient/GetAllPatientsOverviewDTC?patientStatus="+data+"&start="+start+"&end="+end+"&district="+localStorage.getItem("DTCDISTRICTID")));

    }

    return this._http.get(FullUrl,requestOptions);
  }
  getOverviewPatients(data):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patient/GetAllPatients";

    return this._http.post(FullUrl,data,requestOptions);
  }
  getAllTehsils(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetAllTehsils?districtId="+ data;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }
  getDFForDoctors(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/BMU/GetDotFacilitatorForDoctor?districtId="+ data;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }


  getGenderData():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetAllGenders";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }
  getResultOptionsData():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patient/GetSampleResultOptions";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }
   getSeverityData():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patient/GetSampleResultSaverity";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }
  GetRifampicinOptions():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patient/GetRifampicinOptions";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }
  GetPatientRelations():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patient/GetPatientRelations";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }

  getRRValueOptionsData():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patient/GetRRValueOptions";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }
  // api for overall stat Dashboard
GetOAByDate(start,end,user):Observable<any>{
  console.log("data",String(start),end)
  let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/"+(user=="Admin"? "GetOverallStatsByDate" +"?start="+String(start)+"&end="+String(end): "GetOverallStatsByDateDTC" +"?start="+String(start)+"&end="+String(end)+"&district="+String(localStorage.getItem("DTCDISTRICTID")));
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
}
GetOAByDateTehsilDistrict(FilterData):Observable<any>{
  //localStorage.getItem("DTCDISTRICTID")
console.log(FilterData)
  let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetOverallStatsByDateTehsilDistrict?start="+String(FilterData.startDate)+"&end="+String(FilterData.endDate)+"&Tehsil="+String(FilterData.tehsilIds)+"&Gender="+String(FilterData.genderIds)+"&type="+ localStorage.getItem("userType")+((localStorage.getItem("userType")=="Admin"||localStorage.getItem("userType")=="PM")?"&districtid="+FilterData.districtId:"&districtid="+localStorage.getItem("DTCDISTRICTID"));
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
}
  GetOAD():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetOverallStats";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }

}
