import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TehsilService } from '../services/tehsil.service';
import { DistrictsService } from '../services/districts.service';
import { TehsilModel } from '../Model/TehsilModel';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { ExportService } from '../services/export.service';
declare var $: any;

@Component({
  selector: 'app-tehsil',
  templateUrl: './tehsil.component.html',
  styleUrls: ['./tehsil.component.css']
})
export class TehsilComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  TehsilData: Array<any>= new Array();
  DistrictData: Array<any>= new Array();
  isAdmin : boolean = false;
  CreateTehsilData: TehsilModel = new TehsilModel();
  SelectedTehsil: TehsilModel = new TehsilModel();
 // selectedGender : Genders = new Genders();

  constructor(private exportService:ExportService, private TehsilService: TehsilService,private DistrictsService: DistrictsService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService) { }

  ngOnInit() {
    if(localStorage.getItem("role") != null && localStorage.getItem("role")=='Admin'){
      this.isAdmin=true
    }
    this.CreateTehsilData.tehsilName = "";
    this.headerTitle.emit("Tehsils");
    this.isAdmin = true;
    this.TehsilService.GetAllTehsils().subscribe(data=>{
      this.TehsilData = data.body.tehsils;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });

    this.DistrictsService.getAllDistrictData().subscribe(data=>{
    this.DistrictData = data.body.districts;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }

  

  selectedDistrictDetail(data){
    this.SelectedTehsil = data;
  } 

  SelectedDistrict(data){this.CreateTehsilData.districtId = parseInt(data);}

  EditSelectedDistrict(data){this.SelectedTehsil.districtId = parseInt(data);}

  create(){

    this.TehsilService.CreateTehsil(this.CreateTehsilData).subscribe((resp)=>{
      this.Toastr.success('Tehsil Created Successfully ');
      this.ngOnInit();
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  }

  EditTehsil(){

    this.TehsilService.UpdateTehsil(this.SelectedTehsil).subscribe(resp=>{
      this.Toastr.success('Tehsil Updated Successfully ');
      $("#editTehsilModal").modal("hide");
      this.ngOnInit();
      console.log(resp);
    },err=>{
      console.log(err);
    });
  }
  export() {
    this.exportService.exportExcel(this.TehsilData, 'Tehsil Excel');
  
}
}
