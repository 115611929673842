import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'TTA';
}

//Dev

localStorage.setItem('BaseUrl','https://api.tta.asddigital.org');

//Live
 //localStorage.setItem('BaseUrl','https://tta-dev-api.asddigital.org');

 //Livetpt
 //localStorage.setItem('BaseUrl','https://tb-tpt-api.asddigital.org');

 //Devtpt
 //localStorage.setItem('BaseUrl','https://tb-tpt-api-qa.asddigital.org');

 //Live BMU-PMDT
 //localStorage.setItem('BaseUrl','https://bmu-pmdt-api.asddigital.org');

//Local
 //localStorage.setItem('BaseUrl','https://localhost:44367');
