import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DistrictsService } from '../services/districts.service';
import { DistrictModel } from '../Model/DistrictModel';
import { GenericServiceService } from '../services/generic-service.service';
import { Router} from '@angular/router';
import { DashboardServiceService } from '../services/dashboard-service.service';
import { DatePipe } from '@angular/common';
import { ExportService } from '../services/export.service';
import { Doctors } from '../Model/doctors.model';
declare var $: any;

@Component({
  selector: 'app-doctors',
  templateUrl: './doctors.component.html',
  styleUrls: ['./doctors.component.css']
})
export class DoctorsComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  DistrictsData: Array<any>= new Array();
  DFData: Array<any>= new Array();
  DistrictSelected: number;
  SelectedDF:any;
  isAdmin : boolean = false;
  SelectedDoctor: Doctors = new Doctors();
 // selectedGender : Genders = new Genders();
//  updatedist:update=new update()

  constructor(private dashboardService: DashboardServiceService,private genericService: GenericServiceService,private exportService:ExportService, private DistrictsService: DistrictsService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService) { }

  ngOnInit() {
    this.headerTitle.emit("Districts");
    this.isAdmin = true;
    this.DistrictsService.getAllDistrictData().subscribe(data=>{
      this.DistrictsData = data.body.districts;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }

  selectedDFDetail(data){
    this.SelectedDF = data;
    this.SelectedDoctor.DFId=this.SelectedDF
    console.log(data)
  } 

  create(){

    this.DistrictsService.AddNewDF(this.SelectedDoctor).subscribe((resp)=>{
      this.Toastr.success('Doctor Created Successfully ');
      this.ngOnInit();
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  }

  EditDistrict(){

    this.DistrictsService.UpdateDistrict(this.SelectedDoctor).subscribe(resp=>{
      this.Toastr.success('Doctor Updated Successfully ');
      $("#editDistrictModal").modal("hide");
      this.ngOnInit();
      console.log(resp);
    },err=>{
      console.log(err);
    });

  }
  funcSelectedDistrict(data){
    this.DistrictSelected= parseInt(data)
    this.SelectedDoctor.DistrictId=this.DistrictSelected
    this.dashboardService.getDFForDoctors(  this.DistrictSelected).subscribe(dfdata=>{
 this.DFData=dfdata.body.dotFacilitator
   

      
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });

  }
  export() {
    this.exportService.exportExcel(this.DistrictsData, 'District Excel');
  
}
}
