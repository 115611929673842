import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PmdtsiteService } from '../services/pmdtsite.service';
import { DistrictsService } from '../services/districts.service';
import { PMDTModel } from '../Model/PMDTModel';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { ExportService } from '../services/export.service';
declare var $: any;

@Component({
  selector: 'app-pmdtsite',
  templateUrl: './pmdtsite.component.html',
  styleUrls: ['./pmdtsite.component.css']
})
export class PmdtsiteComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  PMDTData: Array<any>= new Array();
  DistrictData: Array<any>= new Array();
  isAdmin : boolean = false;
  CreatePMDTData: PMDTModel = new PMDTModel();
  SelectedPMDT: PMDTModel = new PMDTModel();
  filter:any;


  constructor(private PmdtsiteService: PmdtsiteService ,private DistrictsService: DistrictsService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService, private exportService:ExportService) { }

  ngOnInit() {
    this.CreatePMDTData.pmdtsiteName = "";
    this.headerTitle.emit("PMDT Site");

    $("#phone1").keyup(function() {
      $("#phone1").val(this.value.match(/[0-9]*/));
  });
    if(localStorage.getItem("role") != null && localStorage.getItem("role")=='Admin'){
      this.isAdmin=true
    }    this.PmdtsiteService.GetAllPMDT().subscribe(data=>{
      //this.PMDTData = data.body.pmdtSites;
      this.PMDTData = data.body.pmdtSites.map(tta=>{ var lastupdate=tta.lastUpdated
        console.log("here",tta.lastUpdated);
        tta.lastUpdated=lastupdate?new Date(lastupdate).getDate()+'/'+(new Date(lastupdate).getMonth()+1)+'/'+new Date(lastupdate).getFullYear():'';
        console.log(tta.lastUpdated)
        tta.lastUpdated=  tta.lastUpdated?tta.lastUpdated:"-"
         return tta;});
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });

    this.DistrictsService.getAllDistrictData().subscribe(data=>{
    this.DistrictData = data.body.districts;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }

  ShowSelectedDistrictPMDCData(data){
    this.PMDTData = [];
    this.PmdtsiteService.GetPMDTByDistrict(data).subscribe(data=>{
      this.PMDTData = data.body.pmdtSites;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });


  }

 numbervalidate(){
  $("#phone2").keyup(function() {
    $("#phone2").val(this.value.match(/[0-9]*/));
});
 }
  SelectedPMDTDetail(data){
    this.SelectedPMDT = data;
  }

  SelectedDistrict(data){this.CreatePMDTData.districtId = parseInt(data);}

  EditSelectedDistrict(data){this.SelectedPMDT.districtId = parseInt(data);}

  Create(){

    this.PmdtsiteService.CreatePMDT(this.CreatePMDTData).subscribe((resp)=>{
      this.Toastr.success('PMDT Created Successfully ');
      $("#CreatePMDT").modal("hide");
      this.ngOnInit();
      this.CreatePMDTData.address=''
      this.CreatePMDTData.contact=''
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  }

  Update(){

    this.PmdtsiteService.UpdatePMDT(this.SelectedPMDT).subscribe(resp=>{
      this.Toastr.success('PMDT Updated Successfully ');
      $("#EditPMDTModal").modal("hide");
      this.ngOnInit();
      console.log(resp);
    },err=>{
      console.log(err);
    });
  }
  export() {
    this.exportService.exportExcel(this.PMDTData, 'PMDT Report');

}
}
