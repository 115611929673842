import { Component, EventEmitter, OnInit } from '@angular/core';
import { createlab, Labs, updatelab } from '../Model/labs.model';
import { LabsService } from '../services/labs.service';
import { ToastrService } from 'ngx-toastr';
import { ExportService } from '../services/export.service';
import { GenericServiceService } from "../services/generic-service.service";
declare var $: any;

@Component({
  selector: 'app-labs',
  templateUrl: './labs.component.html',
  styleUrls: ['./labs.component.css']
})
export class LabsComponent implements OnInit {
  headerTitle = new EventEmitter<string>();
  labdata : Array<any> = new Array();
  isAdmin: boolean = false
  labda:any

  Bmudatalist: Array<any> = new Array();
  tehsildefdata : Array<any> = new Array();
  DTC:boolean=false
  showFieldofBMUupdate:boolean=true
  labupdateModel: updatelab = new updatelab();
  createttalab: createlab = new createlab();
  districtdata: Array<any> = new Array();
  districinittdata: Array<any> = new Array();
  TehsilData: Array<any> = new Array();
  TehsildefData: Array<any> = new Array();
  isPM:boolean=false
  tehsilidForbmulab:number
  DistrictsMultiData:any
  showFieldofBMU:boolean=true
  BMUdataFOrID:any
  distidTogetTehsil:number
  constructor(private lab: LabsService, private generic:GenericServiceService, private Toastr: ToastrService , private exportService: ExportService) { }

  ngOnInit() {
    this.headerTitle.emit("GenExpert");
    $("#phone1").keyup(function() {
      $("#phone1").val(this.value.match(/[0-9]*/));
  });
  this.lab.getallBMUdata().subscribe(res=>{

    this.Bmudatalist=res.body.bmus

    var LabIdModel = [];
    this.Bmudatalist.forEach(function (value) {
    if(value.bmuLabId==0)
    {
      LabIdModel.push(value);
    }
 },
 this.BMUdataFOrID=LabIdModel
 );
})

    if ((localStorage.getItem("role") != null && localStorage.getItem("role") == 'Admin')){
      this.isAdmin = true
      this.lab.getTTAdistrict().subscribe(res => {
        this.districinittdata = res.body.districts
        var ActiveDistrictModel = [];
        this.districinittdata.forEach(function (value) {
          if (value.isActive == true) {
            ActiveDistrictModel.push(value);
          }
        },
          this.districtdata = ActiveDistrictModel
        );
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          console.log(error);
        })
        this.lab.getTTAlabData(0).subscribe(data => {
            this.labdata = data.body.labs
            this.labdata = data.body.labs.map(tta=>{ var lastupdate=tta.lastUpdated
              console.log("here",tta.lastUpdated);
              tta.lastUpdated=lastupdate?new Date(lastupdate).getDate()+'/'+(new Date(lastupdate).getMonth()+1)+'/'+new Date(lastupdate).getFullYear():'';
              console.log(tta.lastUpdated)
              tta.lastUpdated=  tta.lastUpdated?tta.lastUpdated:"-"
               return tta;});
           },
             error => {
               this.Toastr.error("Something went on the server side !!!");
               console.log(error);
            })
    }
    if ((localStorage.getItem("role") != null && localStorage.getItem("role") == 'PM')){
      this.isPM = true
      this.lab.getTTAdistrict().subscribe(res => {
        this.districinittdata = res.body.districts
        var ActiveDistrictModel = [];
        this.districinittdata.forEach(function (value) {
          if (value.isActive == true) {
            ActiveDistrictModel.push(value);
          }
        },
          this.districtdata = ActiveDistrictModel
        );
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          console.log(error);
        })
        this.lab.getTTAlabData(0).subscribe(data => {
            this.labdata = data.body.labs
           },
             error => {
               this.Toastr.error("Something went on the server side !!!");
               console.log(error);
            })
    }
    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'DTC') {
      this.DTC = true
       this.lab.getTTAtehsil(localStorage.getItem("DTCDISTRICTID")).subscribe(data => {
      this.tehsildefdata = data.body.tehsils
      var ActiveDistrictModel = [];
      this.tehsildefdata.forEach(function (value) {
        if (value.isActive == true) {
          ActiveDistrictModel.push(value);
        }
      },
        this.TehsilData = ActiveDistrictModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      })
      this.lab.getTTAlabData(localStorage.getItem("DTCDISTRICTID")).subscribe(data => {
        this.labdata = data.body.labs
        this.labda=this.labdata
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          console.log(error);
        });
    }


    $("#phone2").keyup(function() {
      $("#phone2").val(this.value.match(/[0-9]*/));
  });


   }
  ShowSelecteddistrictData(id) {
    this.distidTogetTehsil=id
    this.lab.getTTAtehsil(id).subscribe(data => {
      this.TehsildefData = data.body.tehsils
      var ActiveDistrictModel = [];
      this.TehsildefData.forEach(function (value) {
      if(value.isActive==true)
      {
       ActiveDistrictModel.push(value);
      }
   },
   this.TehsilData=ActiveDistrictModel


   );
    },
    )
    this.lab.getTTAlabData(id).subscribe(data => {
      this.labdata = data.body.labs
      this.labda=this.labdata
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  }
  ShowSelectedtehsilData(tehsilId) {
    this.lab.getTTAtehsils(this.distidTogetTehsil,tehsilId).subscribe(data => {
      this.labdata = data.body.labs
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
}
ShowSelectedtehsilDatas(tehsilId) {


this.lab.getAlllabsForDTC(tehsilId).subscribe(data => {
  this.labdata = data.body.labs
},
  error => {
    this.Toastr.error("Something went on the server side !!!");
    console.log(error);
  });
}
  CreateLab() {
    this.lab.createTTAnewLAB(this.createttalab).subscribe(res => {
      this.Toastr.success("Successfully Created!!!");
      $("#createlabs").modal("hide");
      this.ngOnInit();
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        $("#createlabs").modal("hide");
        this.ngOnInit();
        console.log(error);
      })
  }
  closemodal() {
    $('#createlabs').on('hidden.bs.modal',
      function () {
        $('#createlabs form')[0].reset();
      });
  }
  SelectedTTADetail(data) {
    this.labupdateModel = data;
  }
  BMUIDSELECTED(data){
    this.labupdateModel.bmuId=data
  }
  updatelab() {
    this.lab.updateTTAlab(this.labupdateModel).subscribe(data => {
      this.Toastr.success("Successfully Created!!!");
      $("#updatelab").modal("hide");
      this.ngOnInit();
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        $("#updatelab").modal("hide");
        this.ngOnInit();
        console.log(error);
      })
  }
  export() {
    var exportdata=[];
    this.labdata.forEach(function(v){
      //      Object.keys(v).forEach(function(key) {
      //     var value = v[key];
      //    console.log(key)
      // });
      exportdata.push({
        labName:v.labName,
        userName:v.userName,
        password:v.password,
       contactNo:v.contactNo,
       address:v.address,
       districtName:v.districtName,
       tehsilName:v.tehsilName,
        nameOfLabTech:v.nameOfLabTech,
        isBmulab:v.isBmulab,

        lastUpdated:v.lastUpdated,
        bmuname:v.bmuname,
        isActive:v.isActive
      })
        })
        console.log(exportdata)
    this.exportService.exportExcel(exportdata, 'Labs Excel');
}
showselectedBMUID(data){
  this.createttalab.bmuId=data
}
ShowSelectedtehsilsData(id){
this.createttalab.locality=id
this.tehsilidForbmulab=id
  this.lab.getBMUdata(this.tehsilidForbmulab).subscribe(res=>{

      this.Bmudatalist=res.body.bmus

      var LabIdModel = [];
      this.Bmudatalist.forEach(function (value) {
      if(value.bmuLabId==0)
      {
        LabIdModel.push(value);
      }
   },
   this.DistrictsMultiData=LabIdModel
   );
  })
}
Bmudata(){
  this.showFieldofBMU=false
}
BmudataUpdate(){
  if(this.labupdateModel.isBmulab==true){
    this.showFieldofBMUupdate=true
  }
  else
  this.showFieldofBMUupdate=false
}
}
