import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DistrictsService } from '../services/districts.service';
import { DistrictModel } from '../Model/DistrictModel';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { ExportService } from '../services/export.service';
declare var $: any;

@Component({
  selector: 'app-districts',
  templateUrl: './districts.component.html',
  styleUrls: ['./districts.component.css']
})
export class DistrictsComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  DistrictsData: Array<any>= new Array();
  DistrictName: string;
  DistrictCode: string;
  isAdmin : boolean = false;
  SelectedDistrict: DistrictModel = new DistrictModel();
 // selectedGender : Genders = new Genders();
//  updatedist:update=new update()

  constructor(private exportService:ExportService, private DistrictsService: DistrictsService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService) { }

  ngOnInit() {
    this.headerTitle.emit("Districts");
    this.isAdmin = true;
    this.DistrictsService.getAllDistrictData().subscribe(data=>{
      this.DistrictsData = data.body.districts;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }

  selectedDistrictDetail(data){
    this.SelectedDistrict = data;
  } 

  create(){

    this.DistrictsService.AddNewDistrict(this.SelectedDistrict).subscribe((resp)=>{
      this.Toastr.success('District Created Successfully ');
      this.ngOnInit();
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  }

  EditDistrict(){

    this.DistrictsService.UpdateDistrict(this.SelectedDistrict).subscribe(resp=>{
      this.Toastr.success('District Updated Successfully ');
      $("#editDistrictModal").modal("hide");
      this.ngOnInit();
      console.log(resp);
    },err=>{
      console.log(err);
    });

  }
  export() {
    this.exportService.exportExcel(this.DistrictsData, 'District Excel');
  
}
}
