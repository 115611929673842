export class Bmu {
    bmuName: any 
    address: any
    contact: any
    bmuType: number
    districtId: number
    tehsilId: number
}
export class updateBmu {
    bmuname: any 
    address: any
    contact: any   
 bmuId: number
 isActive:any
 name:any
 dfContact:any
   
}
export class CreateDorFacilitator {
    name: any
    contact: any
    bmuId: number
   
}
