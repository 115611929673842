import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TTA } from '../Model/TTAModel';
import { TTAService } from '../services/tta.service';
import { GenericServiceService } from '../services/generic-service.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { LabsService } from '../services/labs.service';
import { ExportService } from '../services/export.service';
declare var $: any;

@Component({
  selector: 'app-tta',
  templateUrl: './tta.component.html',
  styleUrls: ['./tta.component.css']
})
export class TTAComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  TTAData: Array<TTA> = new Array();
  GenderData: Array<any> = new Array();
  tehsildata: Array<any> = new Array();
  isAdmin: boolean = false;
  showselectedGender: boolean = false;
  CreateTTA: TTA = new TTA();
  SelectedTTA: TTA = new TTA();
  filter: any;
  DTC:boolean=false
  Filtereddistrict: any
  districtdata: Array<any> = new Array();
  districtdefdata: Array<any> = new Array();
  tehsildefdata: Array<any> = new Array();
  isPM:boolean=false
  constructor(private exportService:ExportService, private TTAService: TTAService, private disttehsil: LabsService, private genericService: GenericServiceService, private router: Router, public datepipe: DatePipe, private Toastr: ToastrService) { }

  ngOnInit() {
    this.headerTitle.emit("TTA");
    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'Admin') {
      this.isAdmin = true

    this.genericService.getAllDistrictData().subscribe(res => {
      this.districtdefdata = res.body.districts
      var ActiveDistrictModel = [];
      this.districtdefdata.forEach(function (value) {
        if (value.isActive == true) {
          ActiveDistrictModel.push(value);
        }
      },
        this.districtdata = ActiveDistrictModel


      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      })
 this.TTAService.GetAllTTAsonfireDistrict(0).subscribe(data => {
      this.TTAData = data.body.tbAssistants.map(tta=>{ var lastupdate=tta.lastUpdate
        console.log(lastupdate);
        tta.lastUpdate=lastupdate?new Date(lastupdate).getDate()+'/'+(new Date(lastupdate).getMonth()+1)+'/'+new Date(lastupdate).getFullYear():'';
        tta.cnic=tta.cnic?tta.cnic:"-"
        tta.lastUpdate=  tta.lastUpdate?tta.lastUpdate:"-"

        return tta;});

    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
    }
    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'PM') {
      this.isPM = true

    this.genericService.getAllDistrictData().subscribe(res => {
      this.districtdefdata = res.body.districts
      var ActiveDistrictModel = [];
      this.districtdefdata.forEach(function (value) {
        if (value.isActive == true) {
          ActiveDistrictModel.push(value);
        }
      },
        this.districtdata = ActiveDistrictModel


      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      })
 this.TTAService.GetAllTTAsonfireDistrict(0).subscribe(data => {
     // this.TTAData = data.body.tbAssistants;
      this.TTAData = data.body.tbAssistants.map(tta=>{ var lastupdate=tta.lastUpdate
        console.log(lastupdate);
        tta.lastUpdate=lastupdate?new Date(lastupdate).getDate()+'/'+(new Date(lastupdate).getMonth()+1)+'/'+new Date(lastupdate).getFullYear():'';
        tta.cnic=tta.cnic?tta.cnic:"-"
        tta.lastUpdate=  tta.lastUpdate?tta.lastUpdate:"-"

        return tta;});
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
    }
    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'DTC') {
      this.DTC = true

      this.disttehsil.getTTAtehsil(localStorage.getItem("DTCDISTRICTID")).subscribe(data => {
        this.tehsildefdata = data.body.tehsils
        var ActiveDistrictModel = [];
        this.tehsildefdata.forEach(function (value) {
          if (value.isActive == true) {
            ActiveDistrictModel.push(value);
          }
        },
          this.tehsildata = ActiveDistrictModel
        );
      },
        error => {
          this.Toastr.error("Error Please Select District !!!");
          console.log(error);
        }
      )
      this.TTAService.GetAllTTAsonfireDistrict(localStorage.getItem("DTCDISTRICTID")).subscribe(data => {
       // this.TTAData = data.body.tbAssistants;
        this.TTAData = data.body.tbAssistants.map(tta=>{ var lastupdate=tta.lastUpdate
          console.log(lastupdate);
          tta.lastUpdate=lastupdate?new Date(lastupdate).getDate()+'/'+(new Date(lastupdate).getMonth()+1)+'/'+new Date(lastupdate).getFullYear():'';
          tta.cnic=tta.cnic?tta.cnic:"-"
          tta.lastUpdate=  tta.lastUpdate?tta.lastUpdate:"-"

          return tta;});
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          console.log(error);
        });
    }

    this.TTAService.GetAllGenders().subscribe(data => {
      this.GenderData = data.body.genders;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });

     $("#phone1").keyup(function() {
       $("#phone1").val(this.value.match(/[0-9]*/));
     });

     $("#CNIC1").keyup(function() {
      $("#CNIC1").val(this.value.match(/[0-9]*/));
    });
  }
  valtiadtor(){
    $("#phone").keyup(function() {
      $("#phone").val(this.value.match(/[0-9]*/));
  });

  $("#CNIC").keyup(function() {
    $("#CNIC").val(this.value.match(/[0-9]*/));
});
  }
  ShowSelecteddistrictData(id) {
    this.Filtereddistrict = parseInt(id);
    //  localStorage.setItem("idofdistrictfortta", id)
    localStorage.setItem("DTCDISTRICTID",id);
    this.disttehsil.getTTAtehsil(id).subscribe(data => {
      this.tehsildefdata = data.body.tehsils
      var ActiveDistrictModel = [];
      this.tehsildefdata.forEach(function (value) {
        if (value.isActive == true) {
          ActiveDistrictModel.push(value);
        }
      },
        this.tehsildata = ActiveDistrictModel
      );
    },
      error => {
        this.Toastr.error("Error Please Select District !!!");
        console.log(error);
      }

    )
    this.TTAService.GetAllTTAsonfireDistrict(id).subscribe(data => {
      this.TTAData = data.body.tbAssistants;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  }
  ShowSelectedTehsilTTaData(data) {
    this.TTAData = [];
    this.TTAService.GetAllTTAs(data).subscribe(data => {
      this.TTAData = data.body.tbAssistants;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  }

  SelectedGender(data) { this.CreateTTA.genderId = parseInt(data); }
  SelectedTehsil(data) { this.CreateTTA.tehsilId = parseInt(data); }
  EditSelectedGender(data) { this.SelectedTTA.genderId = parseInt(data); }

  SelectedTTADetail(data) {
    this.SelectedTTA = data;

  }

  Create() {
    this.TTAService.CreateTTA(this.CreateTTA).subscribe((resp) => {
      this.Toastr.success('TTA Created Successfully ');
      $("#CreateTTA").modal("hide");
      this.ngOnInit();
    },
      (error) => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  }

  Update() {
    this.TTAService.UpdateTTA(this.SelectedTTA).subscribe(resp => {
      this.Toastr.success('TTA Updated Successfully ');
      $("#EditTTAModal").modal("hide");
      this.ngOnInit();
    }, error => {
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });

  }
  export() {
    var exportdata=[]
    this.TTAData.forEach(function(v){
  //      Object.keys(v).forEach(function(key) {
  //     var value = v[key];
  //    console.log(key)
  // });
  exportdata.push({
    userName:v.userName,
    password:v.password,
    email:v.email,
    profileName:v.profileName,
    cnic:v.cnic,
   phoneNumber:v.phoneNumber,
   genderName:v.genderName,
   districtName:v.districtName,
   tehsilName:v.tehsilName,
    isActive:v.isActive,
    sampleCollected:v.sampleCollected,
    sampleTransported:v.sampleTransported,
    lastUpdate:v.lastUpdate
  })
    })
console.log(exportdata)
    this.exportService.exportExcel(exportdata, 'TTA Excel');

}
}
