export class Nplabs {
    labName: any
    contact: any
    isNRL: any
}
export class updateNP {
    id:any
    labName: any
    contact: any
    isActive: any
}