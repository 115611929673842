import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class LabsService {

  constructor(private _http:HttpClient,private router:Router) { }
  getTTAlabData(DistrictId:any):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Lab/GetAllLabsForAdmin?DistrictId="+DistrictId;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }
  createTTAnewLAB(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Lab/CreateLAB";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.post(FullUrl,data, requestOptions );
  }
  getTTAdistrict():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetAllDistricts";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }
  getTTAtehsil(DistrictId:any):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetAllTehsils?DistrictId="+DistrictId;   
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }
  getTTAtehsils(DistrictId:any,  tehsilId:any):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Lab/GetAllLabsForAdmin?DistrictId="+DistrictId+"&tehsilId="+tehsilId;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }
  getAlllabsForDTC(tehsilId:any):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Lab/GetAllLabsForAdmin?DistrictId="+localStorage.getItem("DTCDISTRICTID")+"&tehsilId="+tehsilId;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }
  updateTTAlab(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Lab/UpdateLab";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.post(FullUrl,data, requestOptions );
  }
  getBMUdata(tehId:any):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/BMU/GetBMUs?tehsilId="+tehId;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl, requestOptions );
  }
  getallBMUdata():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/BMU/GetBMUs";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl, requestOptions );
  }
}
