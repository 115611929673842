import { Component,EventEmitter, OnInit } from '@angular/core';
import { NPlabsService } from '../services/nplabs.service';
import { ToastrService } from 'ngx-toastr';
import { Nplabs, updateNP } from '../Model/nplabs.model';
import { ExportService } from '../services/export.service';

declare var $: any;
@Component({
  selector: 'app-nationaland-pro-labs',
  templateUrl: './nationaland-pro-labs.component.html',
  styleUrls: ['./nationaland-pro-labs.component.css']
})
export class NationalandProLabsComponent implements OnInit {
  NPLABdata:Array<any>=new Array();
headerTitle = new EventEmitter<string>();
isAdmin:boolean=false
filter:any;
labmodel:Nplabs=new Nplabs();
Updatelabmodel:updateNP=new updateNP();
  constructor(private N_and_p_service:NPlabsService,private Toastr: ToastrService,private exportService:ExportService) { }

  ngOnInit() {
    this.headerTitle.emit("NP Labs");
    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'Admin') {
      this.isAdmin = true
    }
    this.N_and_p_service.getNPlasbsData().subscribe(res=>{
      this.NPLABdata=res.body.npLabs
    })
    $("#phone1").keyup(function() {
      $("#phone1").val(this.value.match(/[0-9]*/));
    });
  }
  validator(){
    $("#phone").keyup(function() {
      $("#phone").val(this.value.match(/[0-9]*/));
    });
  }
createnpLabProfile(){
  this.N_and_p_service.createNPlasbsData(this.labmodel).subscribe(res=>{
    this.Toastr.success('NPlab Created Successfully ');
    $("#CreateNplabs").modal("hide");
    this.ngOnInit();
this.labmodel.contact=''
this.labmodel.labName=''
    },
    (error)=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
}
selectedNpdate(data){
this.Updatelabmodel=data

}
update_nplab(){
  this.N_and_p_service.nplabUpdate(this.Updatelabmodel).subscribe(res=>{

   this.Toastr.success('Updated Successfully ');
    $("#updateNP").modal("hide");
    this.ngOnInit();

    },
    (error)=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
}
export() {
  this.exportService.exportExcel(this.NPLABdata, 'NPlabs Report');

}
}
