export class GetAllPatientsModel{
    contactsUnderFive:any;
    houseHolds:any;
    // patientSamples:any;
    cnic: any;
    // father_HusbandName:any
    fatherHusbandName: any;
    genderId: any;
    genderName: any;
    healthFacilityName: any;
    healthFacilityId:any;
    isReachedHealthFacility: any;
    lastStatusUpdatedAt: any;
    patiendStatus: any;
    patientId: any;
    patientName: any;
    patientSampleNumber: any;
    patientStatusId: any;
    phone: any;
    tehsilId: any;
    tehsilName: any;
    bmuname:any
    drtbnumber:any
    tbnumber:any
    cxrscreened:any
    houseHoldScreened:any
    patientSamples:[
        {
            sampleResults:[
                {
                    severity:any
                }
            ]
        }
    ]
}
export class GetAllPatientsModelEdit{
    patientId: any;
    patientName: any;
    fatherHusbandName:any
    genderId: any;
    genderName: any;
    cnic: any;
    phone: any;
    tbNumber:any
    drtbNumber:any
    houseHoldScreened:any
    CxrScreened:number=0
    patientSampleNumber: any;
    sampleNumber:any
    houseHoldsScreend:any;
}
export class GenePatientsModelEdit{
  PatientId: any;
  PatientName: any;
  FatherHusbandName:any
  NameRelation:any;
  Address:any;
  GenderId: any;
  genderName: any;
  CNIC: any;
  CNICRelation:any;
  Phone: any;
  PhoneRelation: any;
  TBNumber:any
  DRTBNumber:any
  houseHoldScreened:any
  Cxrscreened:number=0
  SampleNumber:any
  HouseHoldsScreened:any;
  createdAt:any;
  SampleCollectedDate:any;
  SampleResult:any;
  ResultAddedDate:any;
  ResultSeverity:any;
  RRValues:any;
  RifampicinOption:any;
  isOtherGenePatient:any;
  isReferredToRl:any;
  Lab:any;



  // public int PatientId { get; set; }
  // public string PatientName { get; set; }
  // public string FatherHusbandName { get; set; }
  // public int NameRelation { get; set; }
  // public int GenderId { get; set; }
  // public string CNIC { get; set; }
  // public int CNICRelation { get; set; }
  // public string Phone { get; set; }
  // public int PhoneRelation { get; set; }
  // public string Address { get; set; }
  // public string Lab { get; set; }
  // public string TBNumber { get; set; }
  // public string DRTBNumber { get; set; }
  // public string SampleNumber { get; set; }
  // public string SampleCollectedDate { get; set; }
  // public int SampleResult { get; set; }
  // public int ResultSeverity { get; set; }
  // public int RRValues { get; set; }
  // public string ResultAddedDate { get; set; }
  // public int RifampicinOption { get; set; }
  // public int HouseHoldsScreened { get; set; }
  // public int Cxrscreened { get; set; }



}
export class refrenacelabsamplesModel{
    isAccepted: any
isReceived: any
labName: any
nplabId: any
nplabSampleNumber: any
pmdtsampleNumber: any
referedToNrldate: any
referedToPrldate: any
sampleUpdatedDateAtNrl: any
sampleUpdatedDateAtPrl: any
isNrl:any
}

export class refrenacesamplesModel{
    isAccepted: any
isReceived: any
labName: any
nplabId: any
nplabSampleNumber: any
pmdtsampleNumber: any
referedToNrldate: any
referedToPrldate: any
sampleUpdatedDateAtNrl: any
sampleUpdatedDateAtPrl: any
isNrl:any
}


