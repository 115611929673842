import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient,HttpParams } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PmdtsiteService {
  constructor(private _http:HttpClient,private router:Router) { }


  GetAllPMDT():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/PmdtSite/GetAllPMDTSite";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }

  GetPMDTByDistrict(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/PmdtSite/GetAllPMDTSite?DistrictId="+data;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }


  CreatePMDT(data):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/PmdtSite/CreatePMDTSite";
    return this._http.post(FullUrl,data,requestOptions);
  }


  UpdatePMDT(data):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/PmdtSite/UpdatePMDTSite";
    return this._http.post(FullUrl,data,requestOptions);
  }
}
