export class Stat {
    sampleReceivedAtTta:number
    sampleSubmittedByTta:number
    sampleReceivedAtLab:number
    resultAdded:number
    pmdtreferred:number
    registeredAtDotsclinic:number
    reachedToPmdt:number
    referedToNrl:number
    referedToPrl:number
    sampleUpdatedAtNrl:number
    sampleUpdatedAtPrl:number
    sampleColledtedAtDf:number
}
