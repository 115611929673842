import { Component, EventEmitter, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BmuService } from '../services/bmu.service';
import { Bmu, updateBmu, CreateDorFacilitator } from '../Model/bmu.model';
import { LabsService } from '../services/labs.service';

import { GenericServiceService } from "../services/generic-service.service";
import { ExportService } from '../services/export.service';
declare var $: any;

@Component({
  selector: 'app-bmu',
  templateUrl: './bmu.component.html',
  styleUrls: ['./bmu.component.css']
})
export class BmuComponent implements OnInit {
  headerTitle = new EventEmitter<string>();
  isAdmin: boolean = false
  DTC: boolean = false

  bmudata: Array<any> = new Array();
  bmutype: Array<any> = new Array();
  districtdata: any
  tehsildata: any='0'
  bmuModelCreateBmu: Bmu = new Bmu();
  updatebmuModel: updateBmu = new updateBmu();
  createdotfaclitatorModel: CreateDorFacilitator = new CreateDorFacilitator
  bmuId: number
  contact: any
  name: any
  // dtcdata: Array<any> = new Array();
  disablebut:boolean=true
  FilteredBMUType: number;
  Filteredtehsil: number
  Filtereddistrict: number
  choosenofBMU: Array<any> = new Array();
  choosenofBMU3: Array<any> = new Array();
  selectedElement:any
  districtdefdata:Array<any>= new Array();
  tehsildefdata: Array<any> = new Array();
  isPM:boolean=false
  constructor(private exportService:ExportService, private bmu: BmuService, private Toastr: ToastrService, private generic: GenericServiceService, private disttehsil: LabsService) { }

  ngOnInit() {
    this.headerTitle.emit("BMU");
    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'Admin') {
      this.isAdmin = true
      this.generic.getAllDistrictData().subscribe(res => {
        this.districtdefdata = res.body.districts
        var ActiveDistrictModel = [];
        this.districtdefdata.forEach(function (value) {
        if(value.isActive==true)
        {
         ActiveDistrictModel.push(value);
        }
     },
     this.districtdata=ActiveDistrictModel


     );
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          console.log(error);
        })
        console.log('done')
        this.bmu.getTTAbmusss(0,0,0).subscribe(data => {
          this.bmudata = data.body.bmus
          this.bmudata = data.body.bmus.map(tta=>{ var lastupdate=tta.lastUpdated
            console.log("here",tta.lastUpdated);
            tta.lastUpdated=lastupdate?new Date(lastupdate).getDate()+'/'+(new Date(lastupdate).getMonth()+1)+'/'+new Date(lastupdate).getFullYear():'';
            console.log(tta.lastUpdated)
            tta.lastUpdated=  tta.lastUpdated?tta.lastUpdated:"-"
             return tta;});
          console.log('return from getTTabmusss')

        })
    }
    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'PM') {
      this.isPM = true
      this.generic.getAllDistrictData().subscribe(res => {
        this.districtdefdata = res.body.districts
        var ActiveDistrictModel = [];
        this.districtdefdata.forEach(function (value) {
        if(value.isActive==true)
        {
         ActiveDistrictModel.push(value);
        }
     },
     this.districtdata=ActiveDistrictModel


     );
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          console.log(error);
        })
        this.bmu.getTTAbmusss(0,0,0).subscribe(data => {
          this.bmudata = data.body.bmus

        })
    }
    $("#phone1").keyup(function() {
      $("#phone1").val(this.value.match(/[0-9]*/));
  });

    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'DTC') {
      this.DTC = true
      this.generic.dtc().subscribe(data=>{
        this.districtdata=data.body.dtc
      })
      this.ShowSelecteddistrictData(localStorage.getItem("DTCDISTRICTID"))

    }
    this.bmu.getBmutype().subscribe(res => {
      console.log('getbmutype',res)
      this.bmutype = res.body.bmuTypes

      this.choosenofBMU = [this.bmutype[0], this.bmutype[1]]
      this.choosenofBMU3 = [this.bmutype[2]]
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      })
        $("#phone3").keyup(function() {
      $("#phone3").val(this.value.match(/[0-9]*/));
  });
  }
  valtiadtor(){
    $("#phone2").keyup(function() {
      $("#phone2").val(this.value.match(/[0-9]*/));
  });
  }
  ShowSelecteddistrictData(id) {
    this.Filtereddistrict = parseInt(id);
    this.bmuModelCreateBmu.districtId=parseInt(id);


    this.disablebut=false
    this.disttehsil.getTTAtehsil(id).subscribe(data => {
      this.tehsildefdata = data.body.tehsils
      var ActiveDistrictModel = [];
      this.tehsildefdata.forEach(function (value) {
      if(value.isActive==true)
      {
       ActiveDistrictModel.push(value);
      }
   },
   this.tehsildata=ActiveDistrictModel


   );

    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      }
    )

  }
  ShowSelectedTehsilData(tehsilid){
this.bmuModelCreateBmu.tehsilId=tehsilid
  }
  filtertehsilreset(){
    $('#tehsil').val('0');
  }
  SelectedBMUTypeforFilter(data) {
    this.FilteredBMUType = parseInt(data);
    this.bmuModelCreateBmu.bmuType=parseInt(data);


  }

//   resetvalueofbmudropdown(){
//       $('#bmuresetto0').val('0');
// this.Filteredtehsil=null
// this.FilteredBMUType=null


//   }
  SelectedtehsilforFilter(data) {
    this.Filteredtehsil = parseInt(data);
    $('#bmuresetto0').val('0');

  }
  hideFields = function () {
    if (this.Filteredtehsil > 0) {
      return true;
    } else {
      return false;
    }
  }
  filterdata() {
    if (this.Filteredtehsil == undefined && this.FilteredBMUType == undefined && this.Filtereddistrict == this.Filtereddistrict) {
      this.bmu.getTTAbmusss(0, 0, this.Filtereddistrict).subscribe(data => {
        this.bmudata = data.body.bmus
      })
       this.Filteredtehsil=null

    }
    else if (this.Filteredtehsil == this.Filteredtehsil && this.FilteredBMUType == undefined && this.Filtereddistrict == this.Filtereddistrict) {

      this.bmu.getTTAbmusss(this.Filteredtehsil, 0, this.Filtereddistrict).subscribe(data => {
        this.bmudata = data.body.bmus
      })
      this.Filteredtehsil=null
      this.FilteredBMUType=null

    }
    else if (this.Filteredtehsil == undefined && this.FilteredBMUType == this.FilteredBMUType && this.Filtereddistrict == this.Filtereddistrict) {
      this.bmu.getTTAbmusss(0, this.FilteredBMUType, this.Filtereddistrict).subscribe(data => {
        this.bmudata = data.body.bmus
      })
      this.Filteredtehsil=null
      this.FilteredBMUType=null

    }
    else
      this.bmu.getTTAbmusss(this.Filteredtehsil, this.FilteredBMUType, this.Filtereddistrict).subscribe(data => {
        this.bmudata = data.body.bmus
      })
      this.Filteredtehsil=null
      this.FilteredBMUType=null

  }
  Createbmu() {
    this.bmu.createttaBMU(this.bmuModelCreateBmu).subscribe(data => {
      $("#createbmu").modal("hide");
      this.Toastr.success("Successfully Created !!!");
      this.ngOnInit();
      this.bmuModelCreateBmu.bmuName = '',
        this.bmuModelCreateBmu.address = '',
        this.bmuModelCreateBmu.contact = ''
    },
      error => {
        $("#createbmu").modal("hide");
        this.bmuModelCreateBmu.bmuName = '',
          this.bmuModelCreateBmu.address = '',
          this.bmuModelCreateBmu.contact = ''
        this.Toastr.error("Something went on the server side !!!");
        this.ngOnInit();
        console.log(error);
      })
  }
  closemodal() {
    $('#createbmu').on('hidden.bs.modal',
      function () {
        $('#createbmu form')[0].reset();
      });
  }
  SelectedTTADetail(data) {
    this.updatebmuModel = data;


  }
  BMUupdation() {
    this.bmu.updateTTAbmu(this.updatebmuModel).subscribe(data => {
      $("#updateBmu").modal("hide");
      this.Toastr.success("Successfully Created !!!");
      this.ngOnInit();
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        this.ngOnInit();
      })
  }
  SelectedTTADetails(item) {
    this.createdotfaclitatorModel = item;
    this.bmuId = item.id

  }
  CreateDotFacilitator() {
    this.bmu.createDF(this.name, this.contact, this.bmuId).subscribe(res => {
      $("#CreatedotfacilitatorModal").modal("hide");
      this.Toastr.success("Successfully Created !!!");
      location.reload();
      this.ngOnInit();
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        this.ngOnInit();
      })
  }
  removedatafromfield() {
    $('#CreatedotfacilitatorModal').on('hidden.bs.modal',
      function () {
        $('#CreatedotfacilitatorModal form')[0].reset();
      });
  }
  export() {
    var exportdata=[]
    this.bmudata.forEach(function(v){
      //      Object.keys(v).forEach(function(key) {
      //     var value = v[key];
      //    console.log(key)
      // });
      exportdata.push({

        address:v.address,
       contact:v.contact,
       type:v.type,
       tehsilName:v.tehsilName,
       districtName:v.districtName,
        isActive:v.isActive ,
        lastUpdated:v.lastUpdated,
        dfContact:v.dfContact,
        userName:v.userName,
        password:v.password,
      })
        })
        console.log(exportdata)
    this.exportService.exportExcel(exportdata, 'BMU Excel');

}
}
