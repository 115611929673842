import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PMService {

  constructor(private _http:HttpClient) { }
  getPMData():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/ProjectManager/GetAllPMs";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }
  CreatePMProfile(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/ProjectManager/CreatePM";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.post(FullUrl,data,requestOptions );
  }
  editPM(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/ProjectManager/UpdatePM";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.post(FullUrl,data,requestOptions );
  }
  deletePm(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/ProjectManager/RemovePM";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.post(FullUrl,data,requestOptions );
  }
}
