export class PM {
    name:any
    phoneNumber:any
}
export class editPm {
name: any
    phoneNumber: any
   id: any
    isActive: any
}
export class removePM {
    id:any
}