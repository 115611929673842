import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GenericServiceService } from '../services/generic-service.service';
import { AllpatientsService } from '../services/allpatients.service';
import { DashboardServiceService } from '../services/dashboard-service.service';
import { Router } from '@angular/router';
import { ExportService } from '../services/export.service';
import { GetAllPatientsModel, GetAllPatientsModelEdit, refrenacelabsamplesModel } from '../Model/PatientModel';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { GetDashboardDataDefault } from '../Model/DashboardModel';
import { PatientstatusService } from '../services/patientstatus.service';
import { ResourceLoader } from '@angular/compiler';
declare var $: any;

@Component({
  selector: 'app-allpatients',
  templateUrl: './allpatients.component.html',
  styleUrls: ['./allpatients.component.css']
})
export class AllpatientsComponent implements OnInit {
  public myModel = ''
  public mask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /[A-Z]/]
  isAdmin: boolean = false;
  showPatientDetails: boolean = false;
  InitialStartDate: Date = new Date;
  InitialEndDate: Date = new Date;
  detailofpatientsample: boolean = false
  p: boolean = null;
  filter: boolean = null;
  @Output() headerTitle = new EventEmitter<string>();
  getallpatientsmodel: Array<any> = new Array();
  RRVALUEData: Array<any> = new Array();
  SelectedPatientDetails: GetAllPatientsModel = new GetAllPatientsModel();
  SelectedPatienttoEditDetails: GetAllPatientsModelEdit = new GetAllPatientsModelEdit();
  GenderData: Array<any> = new Array();
  TehsilData: Array<any> = new Array();
  HealthFacilityData: Array<any> = new Array();
  AppointmentsDetails: Array<any> = new Array();
  IsShowSampleResults: boolean = false;
  SelectedSampleResultData: Array<any> = new Array();
  allPatient: Array<any> = new Array();
  DistrictsData: Array<any> = new Array();
  ResultSeverityData: Array<any> = new Array();
  HouseHoldsPagination: any;
  psDataId: number
  Genderdata: Array<any> = new Array();
  RifamData: Array<any> = new Array();
  fatherHusbandName: any
  DistrictdefData: Array<any> = new Array();
  formvality: boolean = false
  PsData: Array<any> = new Array();
  showTehsilsMultiDropDown: boolean = false
  FilterDashboardData: GetDashboardDataDefault = new GetDashboardDataDefault();
  patientrefrencelabsample: refrenacelabsamplesModel = new refrenacelabsamplesModel();
  patientName: any
  genderId: any
  genderName: any
  cnic: any
  DTC: boolean = false
  phone: any
  address: any
  healthFacilityId: any
  isReachedHealthFacility: any
  TehsildefMultiData: Array<any> = new Array();
  patientId: any
  transportIn: any
  Genderdatas: any
  tehsilId: any
  yoga: boolean = false
  PM: boolean = false
  patient__Id: any
  houseHoldScreened: any;
  tbNumber: any;
  drtbnumber: any;
  cxrscreened: any;
  samplenumber: any;
  cxrScreened: any;
  sampleNumber: any;
  houseHoldsScreend: any;
  constructor(private PatientstatusService: PatientstatusService, private allpatientsService: AllpatientsService, private genericService: GenericServiceService, public datepipe: DatePipe, private router: Router, private exportService: ExportService, private dashboardService: DashboardServiceService, private Toastr: ToastrService) { }

  ngOnInit() {
    this.headerTitle.emit("All Patients");
    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'Admin') {
      this.isAdmin = true
      this.genericService.getAllDistrictData().subscribe(data => {
        this.DistrictdefData = data.body.districts;
        var ActiveDistrictModel = [];
        this.DistrictdefData.forEach(function (value) {
          if (value.isActive == true) {
            ActiveDistrictModel.push(value);
          }
        },
          this.DistrictsData = ActiveDistrictModel
        );
      },
        error => {
          console.log(error);
        });

    }
    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'PM') {
      this.PM = true
      this.genericService.getAllDistrictData().subscribe(data => {
        this.DistrictdefData = data.body.districts;
        var ActiveDistrictModel = [];
        this.DistrictdefData.forEach(function (value) {
          if (value.isActive == true) {
            ActiveDistrictModel.push(value);
          }
        },
          this.DistrictsData = ActiveDistrictModel
        );
      },
        error => {
          console.log(error);
        });

    }
    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'DTC') {
      this.DTC = true
      this.genericService.dtc().subscribe(data => {
        this.DistrictsData = data.body.dtc

      })
      this.SelectedDistrict(  localStorage.getItem("DTCDISTRICTID"))
    }
    // Get District
    this.getallpatientsmodel = [];
    this.allpatientsService.GetAllTehsils().subscribe(data => {
      this.TehsilData = data.body.tehsils;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
    this.dashboardService.getGenderData().subscribe(data => {
      this.GenderData = data.body.genders;
      this.GendersMultiData = this.GenderData;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
    this.PatientstatusService.GetPatientStatus().subscribe(data => {
      this.PsData = data.body.patientStatus;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
    this.detailofpatientsample = true
    this.dashboardService.getGenderData().subscribe(data => {
      this.Genderdatas = data.body.genders;

    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  }

  TehsilMultiData: { [key: string]: Object; }[] = [];
  public TehsilFields: Object = { text: 'tehsilName', value: 'id' };
  public TehsilPlaceholder: string = 'All Tehsils';

  GendersMultiData: { [key: string]: Object; }[] = [];
  public GendersFields: Object = { text: 'genderName', value: 'id' };
  public GendersPlaceholder: string = 'All Genders';

  SelectedpatiantStatus(data) {
    this.psDataId = data
    this.formvality = true

  }

  onSubmit(form: any): void {

    this.FilterDashboardData.tehsilIds = '0';
    this.FilterDashboardData.genderIds = '0';

    // console.log(form.value.name);
    if (form.value.TehsilData !== undefined) {
      if (form.value.TehsilData.length !== 0) {
        this.FilterDashboardData.tehsilIds = form.value.TehsilData.toString();
      }
    }
    if (form.value.GendersData !== undefined) {
      if (form.value.GendersData.length !== 0) {
        this.FilterDashboardData.genderIds = form.value.GendersData.toString();
      }
    }
    this.FilterDashboardData.startDate = this.datepipe.transform(this.InitialStartDate, 'yyyy-MM-dd');
    this.FilterDashboardData.endDate = this.datepipe.transform(this.InitialEndDate, 'yyyy-MM-dd');
    if (this.FilterDashboardData.startDate <= this.FilterDashboardData.endDate) {
      this.FilterDashboardData.patientStatus = this.psDataId
      this.allpatientsService.getAllpatients(this.FilterDashboardData).subscribe(data => {
        $("#patientFilter").modal("hide");
        this.allPatient = data.body.patients;
        $('#psnamereset').val('');
        $('#dato').val('0');



      },

        error => {
          this.Toastr.error("Something went on the server side !!!");
          console.log(error);
        });
    }
    else if (this.FilterDashboardData.startDate > this.FilterDashboardData.endDate) {
      this.Toastr.error('Start date cannot be ahead of End Date');
    }

  }
  openFilter(){
    console.log(this.DTC)
    if(this.DTC){
      //dato
      this.SelectedDistrict(localStorage.getItem("DTCDISTRICTID"))
      console.log('setting value')
       //@ts-ignore
       document.getElementById('dato').value=localStorage.getItem("DTCDISTRICTID")

      }
  }
  SelectedDistrict(data) {
    //  this.mulObj.value = [];
    this.FilterDashboardData.districtId = parseInt(data);
    this.dashboardService.getAllTehsils(this.FilterDashboardData.districtId).subscribe(data => {
      this.TehsilData = data.body.tehsils;
      this.TehsildefMultiData = this.TehsilData;
      this.showTehsilsMultiDropDown = true;
      var ActiveDistrictModel = [];
      this.TehsildefMultiData.forEach(function (value) {
        if (value.isActive == true) {
          ActiveDistrictModel.push(value);
        }
      },
        this.TehsilMultiData = ActiveDistrictModel


      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  }




  selectedPatient(data) {
    this.allpatientsService.getPatientsDetailsByID(data.patientId).subscribe(data => {

      this.SelectedPatientDetails = data.body.patient;
      console.log("newdata of", this.SelectedPatientDetails);

      if (data.body.patient.referenceLabSamples != null) {
        this.patientrefrencelabsample = data.body.patient.referenceLabSamples;
        // this.yoga=true
      }
      else {
        this.patientrefrencelabsample.labName = ''
        this.patientrefrencelabsample.isReceived = ''
        this.patientrefrencelabsample.nplabId = ''
        this.patientrefrencelabsample.nplabSampleNumber = ''
        this.patientrefrencelabsample.pmdtsampleNumber = ''
        this.patientrefrencelabsample.referedToNrldate = ''
        this.patientrefrencelabsample.referedToPrldate = ''
        this.patientrefrencelabsample.sampleUpdatedDateAtNrl = ''
        this.patientrefrencelabsample.sampleUpdatedDateAtPrl = ''
        this.patientrefrencelabsample.isAccepted = ''

      }
      this.showPatientDetails = true;
      this.IsShowSampleResults = false;
    }, error => {
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }

  detailofpatientsamplehidefun() {
    this.detailofpatientsample = !this.detailofpatientsample
  }
  selectedPatienttoEdit(data) {
    this.SelectedPatienttoEditDetails.patientName = data.patientName;
    this.SelectedPatienttoEditDetails.patientId = data.patientId
    this.SelectedPatienttoEditDetails.fatherHusbandName = data.father_HusbandName
    this.SelectedPatienttoEditDetails.genderId = data.genderId
    this.SelectedPatienttoEditDetails.cnic = data.cnic
    this.SelectedPatienttoEditDetails.phone = data.phone
    this.SelectedPatienttoEditDetails.tbNumber = data.tbNumber
    this.SelectedPatienttoEditDetails.drtbNumber = data.drtbNumber
    this.SelectedPatienttoEditDetails.houseHoldScreened = data.houseHoldScreened == null ? 0 : data.houseHoldScreened

    this.SelectedPatienttoEditDetails.CxrScreened = data.cxrScreened == null ? 0 : data.cxrScreened
    this.SelectedPatienttoEditDetails.sampleNumber = data.patientSampleNumber

    // this.patientName=data.patientName
    //  this.fatherHusbandName=data.father_HusbandName
    //  this.genderId=data.genderId
    //  this.genderName=data.genderName

    //  this.cnic=data.cnic
    //  this.phone=data.phone
    //   this.tbNumber=data.tbNumber
    //   this.drtbnumber=data.drtbNumber
    //   this.houseHoldsScreend=data.houseHoldsScreend
    //   this.cxrScreened=data.cxrScreened
    //   this.sampleNumber=data.sampleNumber
    //  this.patientId=data.patientId
  }

  // SelectedTehsilForPatient(data){
  //   this.SelectedPatienttoEditDetails.tehsilId = parseInt(data);
  // }

  SelectedGenderForPatient(data) {
    this.SelectedPatienttoEditDetails.genderId = data;
  }
  // SelectedHealthFacilityForPatient(data){
  //   this.SelectedPatienttoEditDetails.healthFacilityId = parseInt(data);
  // }
  getCallData(data) {
    console.log("data", data);

    this.allpatientsService.getRRoption().subscribe(res => {
      this.RRVALUEData = res.body.rrValueOptions
    })
    this.allpatientsService.getSaverity().subscribe(res => {
      this.ResultSeverityData = res.body.sampleSaverity
    })
    this.allpatientsService.getRifam().subscribe(res => {
      this.RifamData = res.body.rifampicinOption
    })
    this.allpatientsService.getPatientsDetailsByID(data.patientId).subscribe(data => {
      this.SelectedPatientDetails = data.body.patient;
    })
  }
  UpdatePatient() {
    this.allpatientsService.Update(
      // this.patientName,
      // this.fatherHusbandName,
      // this.SelectedPatienttoEditDetails.genderId,
      // this.cnic,
      // this.phone,
      // this.tbNumber,
      // this.drtbnumber,
      // this.houseHoldsScreend,
      // this.cxrScreened,
      // this.samplenumber,
      // this.patientId,
      this.SelectedPatienttoEditDetails
    ).subscribe(resp => {
      this.Toastr.success('Patient Updated Successfully ');
      $("#EditPatient").modal("hide");
      this.ngOnInit();
      // window.location.reload()
    }, err => {
      this.Toastr.error("Something went on the server side !!!");
      console.log(err);
    });
  }

  ShowSampleResults(data) {
    this.SelectedSampleResultData = data;
    this.IsShowSampleResults = true;
  }
  selectedPatienttodelete(data) {
    console.log(data.patientId);
    this.patient__Id = data.patientId

  }
  deletePatient() {
    this.genericService.removePatient(this.patient__Id).subscribe(res => {

      $("#delete__Patient").modal("hide");
      this.Toastr.success('Successfully Deleted');
      this.ngOnInit();
      location.reload()
    }, err => {
      this.Toastr.error('Error 302');
    });
  }
  export() {
    this.exportService.exportExcel(this.allPatient, 'AllPatientsReport');

  }

}
