import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class ClinicService {

  constructor(private _http:HttpClient,private router:Router) { }
  // getTTAclinicData(districtId:any):Observable<any>{

  //   let FullUrl = localStorage.getItem('BaseUrl')+"/api/Clinic/GetAllClinicsForAdmin?districtId="+districtId;
  //   localStorage.setItem("distIDforclinic", districtId)
  //   const headerDict = {
  //     'Access-Control-Allow-Origin':'*',
  //     "Authorization": "Bearer " + localStorage.getItem('access_token'),
  //     'Accept': 'application/json'
  //   }
  //   const requestOptions = {                                                                                                                                                                                 
  //     headers: new HttpHeaders(headerDict), 
  //   };

  //   return this._http.get(FullUrl,requestOptions );
  // }
  getTTAclinicDataontehsil(tehsilId:any):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Clinic/GetAllClinicsForAdmin?DistrictId="+localStorage.getItem("distIDforclinic")+"&tehsilId="+tehsilId;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }
  TTAcreateclicic(data):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"​/api/Clinic/CreateClinic";
    return this._http.post(FullUrl,data,requestOptions);
  }
  updateTTAclinic(data):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"​/api/Clinic/UpdateClinic";
    return this._http.post(FullUrl,data,requestOptions);
  }
}
