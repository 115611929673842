export class TTA{
    id:any;
    profileName: any;
    phoneNumber: any;
    password: any;
    lostToFollowUp: any;
    houseHoldAdded: any;
    genderName: any;
    genderId: any;
    cnic: any;
    email:any;
    registeredPatients: any;
    resultsAdded: any;
    sampleCollected: any;
    sampleTransported: any;
    tehsilName: any;
    tehsilId: any;
    totalPatients: any;
    userName: any;
    isActive: any;
    districtName:any;
    lastUpdate:any;
}

/* export class UpdateTTAModel {

    profileName: any;
    phoneNumber: any;
    password: any;
    lostToFollowUp: any;
    houseHoldAdded: any;
    genderName: any;
    cnic: any;
    registeredPatients: any;
    resultsAdded: any;
    sampleCollected: any;
    sampleTransported: any;
    tehsilName: any;
    totalPatients: any;
    userName: any;
} */
