import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class BmuService {

  constructor(private _http:HttpClient,private router:Router) { }
  getTTAbmusss(tehsilId:any,bmuTypeId:any,districtId:any):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/BMU/GetBMUs?tehsilId="+tehsilId+"&bmuTypeId="+bmuTypeId+"&districtId="+districtId;
console.log('returning from get ttabmu')

    return this._http.get(FullUrl,requestOptions );
  }
  getBmutype():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/BMU/GetBMUTypes";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this._http.get(FullUrl,requestOptions );
  }
  createttaBMU(data):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/BMU/CreateBMU";
    return this._http.post(FullUrl,data,requestOptions);
  }
  updateTTAbmu(data):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/BMU/UpdateBMU";
    return this._http.post(FullUrl,data,requestOptions);
  }
  createDF(name,contact,bmuId):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/BMU/CreateDotFacilitator";
    let data={ Contact:contact,Name:name,bmuId:bmuId}
    return this._http.post(FullUrl,data,requestOptions);
  }
}
