import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { AuthGuard} from './auth/auth.guard';
import { AllpatientsComponent } from './allpatients/allpatients.component';
import { GenderComponent } from './gender/gender.component';
import { DistrictsComponent } from './districts/districts.component';
import { DoctorsComponent } from './doctors/doctors.component';
import { TTAComponent } from './tta/tta.component';
import { TehsilComponent } from './tehsil/tehsil.component';
import { HealthfacilityComponent } from './healthfacility/healthfacility.component';
import { PmdtsiteComponent } from './pmdtsite/pmdtsite.component';
import { PatientstatusComponent } from './patientstatus/patientstatus.component';
import { LabsComponent } from './labs/labs.component';
import { ClinicComponent } from './clinic/clinic.component';
import { BmuComponent } from './bmu/bmu.component';
import { DtcComponent } from './dtc/dtc.component';
import { NationalandProLabsComponent } from './nationaland-pro-labs/nationaland-pro-labs.component';
import { ProjectManagerComponent } from './project-manager/project-manager.component';
import { StatComponent } from './stat/stat.component';
import { GenePatientsComponent } from './gene-patients/gene-patients.component';

const routes: Routes = [
  {
    path:'',redirectTo: 'login', pathMatch: 'full'
  },
  {
    // path: 'dashboard', 
    //     component: AppLayoutComponent,
    //     children: [
    //       { path: '', component: DashboardComponent, pathMatch: 'full', canActivate:[AuthGuard]}
    //     ]
    path: 'Currentstat', 
        component: AppLayoutComponent,
        children: [
          { path: '', component: DashboardComponent, pathMatch: 'full' , canActivate:[AuthGuard]}
        ]
  },
  {
    path: 'Dashboard',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: StatComponent, pathMatch: 'full'
      }
    ]
  },
{
  path: 'Allpatients',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: AllpatientsComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'Genepatients',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: GenePatientsComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'Gender',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: GenderComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'Districts',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: DistrictsComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'Doctors',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: DoctorsComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'Tehsils',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: TehsilComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'TTA',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: TTAComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'labs',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: LabsComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'clinic',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: ClinicComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'dtc',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: DtcComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'bmu',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: BmuComponent, pathMatch: 'full'
    }
  ]
},

{
  path: 'HealthFacility',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: HealthfacilityComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'PMDTSite',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: PmdtsiteComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'PatientStatus',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: PatientstatusComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'project-manager',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: ProjectManagerComponent, pathMatch: 'full'
    }
  ]
},
{
  path: 'nplabs',
  component: AppLayoutComponent,
  children: [
    {
      path: '', component: NationalandProLabsComponent, pathMatch: 'full'
    }
  ]
},
{ 
  path: 'login', 
  component: LoginComponent
},
 { path: '**', redirectTo: 'Dashboard' }];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
