export class DistrictModel{
     id:number;
    districtName: string;
    code: string;
    isActive: any

}

// export class update{
//     districtId:number;
//             districtName: any;
//         districtCode: any;
//         isActive: any
      
// }