import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PatientStatusModel } from '../Model/PatientStatusModel';
import { PatientstatusService } from '../services/patientstatus.service';
import { GenericServiceService } from '../services/generic-service.service';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-patientstatus',
  templateUrl: './patientstatus.component.html',
  styleUrls: ['./patientstatus.component.css']
})
export class PatientstatusComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  genderData: Array<PatientStatusModel>= new Array();
  isAdmin : boolean = false;
  showselectedGender: boolean = false;
  public  GenderName: string;
  selectedGender : PatientStatusModel = new PatientStatusModel();

  constructor(private PatientstatusService: PatientstatusService, private genericService: GenericServiceService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService) { }

  ngOnInit() {
    this.headerTitle.emit("Patient Status");
    this.isAdmin = true;
    this.PatientstatusService.GetPatientStatus().subscribe(data=>{
      this.genderData = data.body.patientStatus;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }

  selectedGenderDetail(gender : PatientStatusModel){
    this.selectedGender = gender;
    this.showselectedGender = true;
  }

  createGender(){

    this.PatientstatusService.CreatePatientStatus(this.GenderName).subscribe((resp)=>{

      this.Toastr.success('Patient Status Created Successfully ');
      this.ngOnInit();
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  }

  EditGender(){

    this.PatientstatusService.UpdatePatientStatus(this.selectedGender.id,this.selectedGender.patientStatus,this.selectedGender.isActive ).subscribe(resp=>{
      this.Toastr.success('Patient Status Updated Successfully ');
      $("#editGenderModal").modal("hide");
      this.ngOnInit();
      console.log(resp);
    },error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });

  }

}
