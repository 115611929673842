export class Clinic {
  clinicName: any
  doctorName: any
  address: any
  contactNo: any
  locality: 0
}

export class updateclinic{
  clinicName: any
  doctorName: any
  address: any
  contactNo: any
  locality: 0
  isActive:any
  id: number

}
