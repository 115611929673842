export class Labs {
    id: number
    labName: any
    contactNo: any
    address: any
    tehsilName: any
    nameOfLabTech: any
 
}
export class createlab {
    // id: number
    labName: any
    address: any
    contactNo: any
    nameOfLabTech: any
     labStatus: any
     locality:number
     isBMULab:any=false
     bmuId:any
}

export class updatelab{
     
    labName: any
        address: any
        contactNo: any
        nameOfLabTech: any
         locality:number
         labId: number
         bmuId:any
         bmuname:any
         isBmulab:any
         isActive:any
      }


