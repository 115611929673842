import { Component, EventEmitter, OnInit } from '@angular/core';
import { Dtc, updateDtc } from '../Model/dtc.model';
import { DtcService } from '../services/dtc.service';
import { ToastrService } from 'ngx-toastr';
import { ExportService } from '../services/export.service';

declare var $: any;
@Component({
  selector: 'app-dtc',
  templateUrl: './dtc.component.html',
  styleUrls: ['./dtc.component.css']
})
export class DtcComponent implements OnInit {
  headerTitle = new EventEmitter<string>();
  getdtcdata: Array<any> = new Array();
  genterdata: Array<any> = new Array();
  Districtdata:Array<any>=new Array();
  dtcdata: Dtc = new Dtc();
  districtId:number
  name: any
    phone: any
    gender: any
    phoneno:any
    isPM:boolean=false
  SelectedTTADtc: updateDtc = new updateDtc();

  constructor(private dtcinfo: DtcService, private Toastr: ToastrService, private exportService:ExportService) { }

  ngOnInit() {
    this.headerTitle.emit("DTC");
    if(localStorage.getItem("role") != null && localStorage.getItem("role") == 'PM'){
this.isPM=true
    }
    this.dtcinfo.getTTAdtc().subscribe(res => {
      this.getdtcdata = res.body.dtCs
    },
      error => {
        this.Toastr.error("Error | Can't Get DTC's !!!");
        console.log(error);
      })
    this.dtcinfo.getTTAgender().subscribe(res => {
      this.genterdata = res.body.genders
    },
      error => {
        this.Toastr.error("Error | Can't Get Gender !!!");
        console.log(error);
      })
      this.dtcinfo.GetallDistrict().subscribe(data=>{
        this.Districtdata=data.body.districts
      })
      $("#phone").keyup(function() {
        $("#phone").val(this.value.match(/[0-9]*/));
    });
    $("#phone1").keyup(function() {
      $("#phone1").val(this.value.match(/[0-9]*/));
  });
 
    }
  
  SelectedTehsil(data){
     this.districtId=data
  }
  CreateDtc() {
    this.dtcinfo.createttaDTC(this.name,this.phone,this.gender,this.districtId).subscribe(res => {
      $("#createdtc").modal("hide");
      this.Toastr.success("Successfully Created!!!");
      this.ngOnInit();
      this.dtcdata.name = '',
        this.dtcdata.phone = null
        // this.dtcdata.gender = ''


    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      })
  }
  closemodal() {
    $('#createdtc').on('hidden.bs.modal',
      function () {
        $('#createdtc form')[0].reset();
      });
  }


  SelectedTTADetail(data){
    this.SelectedTTADtc = data;
  }

  updatedtc() {
    this.dtcinfo.Updatettadtc(this.SelectedTTADtc).subscribe(res => {
      $("#updatedtcmodal").modal("hide");
      this.Toastr.success("Successfully Created!!!");
      this.ngOnInit();
    },
    error => {
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    })
  }
  export() {
    this.exportService.exportExcel(this.getdtcdata, 'DTC Excel');
  
}
}
