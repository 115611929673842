export class HealthFacility
{
    healthFacilityId: any;
    healthFacilityName:any;
    contact:any;
    address:any;
    tehsilId:any;
    houseHoldAdded:any;
    lostToFollowUp:any;
    registeredPatients:any;
    resultsAdded:any;
    sampleCollected:any;
    sampleTransported:any;
    tehsilName:any;
    totalPatients:any;
    isActive: any;

}