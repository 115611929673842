import { Component, OnInit ,Output,EventEmitter, ViewChild} from '@angular/core';
import { DashboardServiceService } from '../services/dashboard-service.service';
import { GenericServiceService } from '../services/generic-service.service';
import { Router} from '@angular/router';
import {FOrDTC, DashboardData,GetDashboardDataDefault } from '../Model/DashboardModel';
import { DatePipe } from '@angular/common';
//  import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ToastrService } from 'ngx-toastr';
import { PatientstatusService } from '../services/patientstatus.service';
import { AllpatientsService } from '../services/allpatients.service';
import { GetAllPatientsModel,refrenacesamplesModel } from '../Model/PatientModel';
import { ExportService } from '../services/export.service';
import * as Chart from 'chart.js';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  // @ViewChild('muliselect')
  // public mulObj: MultiSelectComponent;
  GetDashboardDataDefault : GetDashboardDataDefault = new GetDashboardDataDefault();
  dashboardData: DashboardData = new DashboardData();
  dashboardPatientsData : Array<any> = new Array();
  showPatient : boolean = false;
  showPatientDetails : boolean = false;
  IsShowSampleResults:boolean = false;
  SelectedSampleResultData:boolean=false
  graphpatientStatus:any
  SelectedPatientDetails: GetAllPatientsModel = new GetAllPatientsModel();
  selectedPatientType : string = "";
  @Output() headerTitle = new EventEmitter<string>();
  rcTargetModel : Array<any> = new Array();
  graphdata:any
  DistrictsData : Array<any> = new Array();
  TehsilData : Array<any> = new Array();
  GenderData : Array<any> = new Array();
  FilterDashboardData : GetDashboardDataDefault = new GetDashboardDataDefault();
  FilterDashboardDataforDTC:FOrDTC=new FOrDTC()
  showTehsilsMultiDropDown : boolean = false;
  InitialStartDate: Date = new Date;
  InitialEndDate:  Date = new Date;
  TTAdashboardData: any;
  TTATotalPatients:number
  statusid:number
  DistrictdID:any
  did:number
  chart:any;
  patientrefrencelabsample: refrenacesamplesModel=new refrenacesamplesModel();
  TTADefdashboardData: Array<any> = new Array();
  hideform:boolean=false
  DistrictdefData: Array<any> = new Array();
  TehsildefMultiData: Array<any> = new Array();
  constructor(private exportService:ExportService, private allpatientsService:AllpatientsService, private dashboardService: DashboardServiceService, private genericService: GenericServiceService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService) { }
    DTC:boolean=false
    isAdmin : boolean = false;

    
  ngOnInit() {
    this.InitialStartDate.setMonth(this.InitialStartDate.getMonth() - 12);
    // Get District
      this.headerTitle.emit("Current Stats");
      

      
      if(localStorage.getItem("role") != null && localStorage.getItem("role")=='Admin' || localStorage.getItem("role") != null && localStorage.getItem("role")=='PM'){
        this.isAdmin=true
        this.genericService.getAllDistrictData().subscribe(data=>{
          this.DistrictdefData = data.body.districts;
          var ActiveDistrictModel = [];
          this.DistrictdefData.forEach(function (value) {
          if(value.isActive==true)
          {
           ActiveDistrictModel.push(value);
          }
       },
       this.DistrictsData=ActiveDistrictModel
       );     
        },
        error=>{
          console.log(error);
        });
        this.FilterDashboardData.startDate = "";
      this.FilterDashboardData.endDate = "";
      this.dashboardService.getTTADashboardData(this.FilterDashboardData).subscribe(data=>{
        this.TTADefdashboardData = data.body.patientStatus;
         this.TTATotalPatients = data.body.totalPatients
        var ActiveDistrictModel = [];
          this.TTADefdashboardData.forEach(function (value) {
          if(value.id==1)
          {
            value.patientStatus='Registered Patients By DF'
           ActiveDistrictModel.push(value);
          }
       },
       this.TTAdashboardData=ActiveDistrictModel
       ); 
       this.TTADefdashboardData.forEach(function (value) {
        if(value.id==2)
        {
          value.patientStatus='Samples collected by DOTS Facilitator'
         ActiveDistrictModel.push(value);
        }
     },
     this.TTAdashboardData=ActiveDistrictModel
     );
     this.TTADefdashboardData.forEach(function (value) {
      if(value.id==3)
      {
        value.patientStatus='SAMPLEs RECEIVED by TTA'
       ActiveDistrictModel.push(value);
      }
   },
   this.TTAdashboardData=ActiveDistrictModel
   );
   this.TTADefdashboardData.forEach(function (value) {
    if(value.id==4)
    {
      value.patientStatus=' Samples Transported by TTA'
     ActiveDistrictModel.push(value);
    }
 },
 this.TTAdashboardData=ActiveDistrictModel
 );
 this.TTADefdashboardData.forEach(function (value) {
  if(value.id==5)
  {
    value.patientStatus='Samples Received at GeneXpert Labs'
   ActiveDistrictModel.push(value);
  }
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
  if(value.id==6)
  {
    value.patientStatus='Results added at GeneXpert Labs'
   ActiveDistrictModel.push(value);
  }
},
this.TTAdashboardData=ActiveDistrictModel
);


this.TTADefdashboardData.forEach(function (value) {
  if(value.id==8)
  {
    value.patientStatus='Registered at DOTS Clinic'
   ActiveDistrictModel.push(value);
  }
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
  if(value.id==7)
  {
    value.patientStatus='Referred to PMDT Site'
   ActiveDistrictModel.push(value);
  }
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
  if(value.id==9)
  {
    value.patientStatus='Registered at PMDT Site'

   ActiveDistrictModel.push(value);
  }
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
  if(value.id==10 )
  {
value.patientStatus='Samples sent to NRL'
   ActiveDistrictModel.push(value);
  }
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
  if(value.id==11 )
  {
    value.patientStatus='Samples sent to PRL'
   ActiveDistrictModel.push(value);
  }
},
this.TTAdashboardData=ActiveDistrictModel
);
var ActiveDistrictModelofpatientStatus = [];

this.TTADefdashboardData.forEach(function (value) {
  if(value.patientStatus )
  { 
    ActiveDistrictModelofpatientStatus.push(value.patientStatus);  
  }
},
this.graphpatientStatus=ActiveDistrictModelofpatientStatus,
);
var ActiveDistrictModels = [];

this.TTADefdashboardData.forEach(function (value) {
  if(value.patients )
  { 
    ActiveDistrictModels.push(value.patients);  
  }
},
this.graphdata=ActiveDistrictModels,
);
const canvas = <HTMLCanvasElement> document.getElementById('myChart');
const ctx = canvas.getContext('2d');
var myChart = new Chart(ctx, {
    type: 'bar',
    data: {
        labels: [this.graphpatientStatus[0],this.graphpatientStatus[1],this.graphpatientStatus[2],this.graphpatientStatus[3],this.graphpatientStatus[4],this.graphpatientStatus[5],this.graphpatientStatus[6],this.graphpatientStatus[7],this.graphpatientStatus[8],this.graphpatientStatus[9],this.graphpatientStatus[10]
      ],
        datasets: [{
            label: 'No. of Patients',
            data: [this.graphdata[0],this.graphdata[1],this.graphdata[2],this.graphdata[3],this.graphdata[4],this.graphdata[5],this.graphdata[6],this.graphdata[7],this.graphdata[10],this.graphdata[8],this.graphdata[9],],
            backgroundColor: [
                'rgba(255, 0, 0, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
                'rgba(128, 0, 0, 1)',
                'rgba(128, 128, 0, 1)',
                'rgba(0, 0, 128, 1)',
                'rgba(128, 0, 128, 1)',
                'rgba(0, 128, 128, 1)',
            ],
     
        }]
    },
    // options: {
    //   legend: {
    //     display: false,
    //   },
    //   scales: {
    //     yAxes: [{
    //       ticks: {
    //         beginAtZero: true,
    //         stepSize: 1, // I'm getting an error here
    //       },
    //     }],
    //   },
    // },
});

 



},

      error=>{
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });

      }
     
      if(localStorage.getItem("role") != null && localStorage.getItem("role")=='DTC'){
        this.DTC=true
        this.genericService.dtc().subscribe(data=>{
          this.DistrictsData=data.body.dtc
        })
        this.FilterDashboardDataforDTC.startDate = "";
        this.FilterDashboardDataforDTC.endDate = "";
        this.dashboardService.getTTADashboardData(this.FilterDashboardDataforDTC).subscribe(data=>{
          this.TTADefdashboardData = data.body.patientStatus;
          this.TTATotalPatients = data.body.totalPatients
         var ActiveDistrictModel = [];
           this.TTADefdashboardData.forEach(function (value) {
           if(value.id==1)
           {
             value.patientStatus='Registered Patients By DF'
            ActiveDistrictModel.push(value);
           }
        },
        this.TTAdashboardData=ActiveDistrictModel
        ); 
        this.TTADefdashboardData.forEach(function (value) {
         if(value.id==2)
         {
           value.patientStatus='SAMPLES COLLECTED BY DOTS FACILITATOR'
          ActiveDistrictModel.push(value);
         }
      },
      this.TTAdashboardData=ActiveDistrictModel
      );
      this.TTADefdashboardData.forEach(function (value) {
       if(value.id==3)
       {
         value.patientStatus='SAMPLEs RECEIVED by TTA'
        ActiveDistrictModel.push(value);
       }
    },
    this.TTAdashboardData=ActiveDistrictModel
    );
    this.TTADefdashboardData.forEach(function (value) {
     if(value.id==4)
     {
       value.patientStatus=' Samples Transported by TTA'
      ActiveDistrictModel.push(value);
     }
  },
  this.TTAdashboardData=ActiveDistrictModel
  );
  this.TTADefdashboardData.forEach(function (value) {
   if(value.id==5)
   {
     value.patientStatus='Samples Received at GeneXpert Labs'
    ActiveDistrictModel.push(value);
   }
 },
 this.TTAdashboardData=ActiveDistrictModel
 );
 this.TTADefdashboardData.forEach(function (value) {
   if(value.id==6)
   {
    value.patientStatus='Results added at GeneXpert Labs'
    ActiveDistrictModel.push(value);
   }
 },
 this.TTAdashboardData=ActiveDistrictModel
 );
 this.TTADefdashboardData.forEach(function (value) {
  if(value.id==8)
  {
   value.patientStatus='Registered at DOTS Clinic'
   ActiveDistrictModel.push(value);
  }
},
this.TTAdashboardData=ActiveDistrictModel
);
 this.TTADefdashboardData.forEach(function (value) {
   if(value.id==7)
   {
     value.patientStatus='Referred to PMDT Site'
    ActiveDistrictModel.push(value);
   }
 },
 this.TTAdashboardData=ActiveDistrictModel
 );

 this.TTADefdashboardData.forEach(function (value) {
   if(value.id==9)
   {
     value.patientStatus='Registered at PMDT Site'
 
    ActiveDistrictModel.push(value);
   }
 },
 this.TTAdashboardData=ActiveDistrictModel
 );
 this.TTADefdashboardData.forEach(function (value) {
   if(value.id==10 )
   {
    value.patientStatus='Samples sent to NRL'
    ActiveDistrictModel.push(value);
   }
 },
 this.TTAdashboardData=ActiveDistrictModel
 );
 this.TTADefdashboardData.forEach(function (value) {
   if(value.id==11 )
   {
    value.patientStatus='Samples sent to PRL'
    ActiveDistrictModel.push(value);
   }
 },
 this.TTAdashboardData=ActiveDistrictModel
 );
 var ActiveDistrictModelofpatientStatus = [];

 this.TTADefdashboardData.forEach(function (value) {
   if(value.patientStatus )
   { 
     ActiveDistrictModelofpatientStatus.push(value.patientStatus);  
   }
 },
 this.graphpatientStatus=ActiveDistrictModelofpatientStatus,
 );
 var ActiveDistrictModels = [];
 
 this.TTADefdashboardData.forEach(function (value) {
   if(value.patients )
   { 
     ActiveDistrictModels.push(value.patients);  
   }
 },
 this.graphdata=ActiveDistrictModels,
 );
 const canvas = <HTMLCanvasElement> document.getElementById('myChart');
 const ctx = canvas.getContext('2d');
 var myChart = new Chart(ctx, {
     type: 'bar',
     data: {
         labels: [this.graphpatientStatus[0],this.graphpatientStatus[1],this.graphpatientStatus[2],this.graphpatientStatus[3],this.graphpatientStatus[4],this.graphpatientStatus[5],this.graphpatientStatus[6],this.graphpatientStatus[7],this.graphpatientStatus[8],this.graphpatientStatus[9],this.graphpatientStatus[10]
       ],
         datasets: [{
             label: 'No. of Patients',
             data: [this.graphdata[0],this.graphdata[1],this.graphdata[2],this.graphdata[3],this.graphdata[4],this.graphdata[5],this.graphdata[6],this.graphdata[7],this.graphdata[10],this.graphdata[8],this.graphdata[9],],
             backgroundColor: [
                 'rgba(255, 0, 0, 1)',
                 'rgba(54, 162, 235, 1)',
                 'rgba(255, 206, 86, 1)',
                 'rgba(75, 192, 192, 1)',
                 'rgba(153, 102, 255, 1)',
                 'rgba(255, 159, 64, 1)',
                 'rgba(128, 0, 0, 1)',
                 'rgba(128, 128, 0, 1)',
                 'rgba(0, 0, 128, 1)',
                 'rgba(128, 0, 128, 1)',
                 'rgba(0, 128, 128, 1)',
             ],
      
         }]
     },
     // options: {
     //   legend: {
     //     display: false,
     //   },
     //   scales: {
     //     yAxes: [{
     //       ticks: {
     //         beginAtZero: true,
     //         stepSize: 1, // I'm getting an error here
     //       },
     //     }],
     //   },
     // },
 });
 
  
 
 
    
          
        },
        error=>{
          this.Toastr.error("Something went on the server side !!!");
          console.log(error);
        });
  
      }
    

  
    this.dashboardService.getGenderData().subscribe(data=>{
      this.GenderData = data.body.genders;
      this.GendersMultiData = this.GenderData;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    }); 


  }
  onSubmit(form: any): void {
    this.FilterDashboardData.tehsilIds = '0';
    this.FilterDashboardData.genderIds = '0';
    if(form.value.TehsilData !== undefined){
      if(form.value.TehsilData.length !== 0){
        this.FilterDashboardData.tehsilIds = form.value.TehsilData.toString(); 
      }    
    }
  if(form.value.GendersData !== undefined){
    if(form.value.GendersData.length !== 0){  
      this.FilterDashboardData.genderIds = form.value.GendersData.toString(); 
    }
  }
  this.FilterDashboardData.startDate = this.datepipe.transform(this.InitialStartDate, 'yyyy-MM-dd');
  this.FilterDashboardData.endDate = this.datepipe.transform(this.InitialEndDate, 'yyyy-MM-dd');

  
   if(this.FilterDashboardData.startDate <= this.FilterDashboardData.endDate){
     this.dashboardService.getTTADashboardData(this.FilterDashboardData).subscribe(data=>{
      $("#DashBoardFilter").modal("hide");
      this.TTADefdashboardData = data.body.patientStatus;
      this.TTATotalPatients = data.body.totalPatients
      var ActiveDistrictModel = [];
      this.TTADefdashboardData.forEach(function (value) {
      if(value.id==1)
      {
        value.patientStatus='Registered Patients By DF'
       ActiveDistrictModel.push(value);
      }
   },
   this.TTAdashboardData=ActiveDistrictModel
   ); 
   this.TTADefdashboardData.forEach(function (value) {
    if(value.id==2)
    {
      value.patientStatus='SAMPLES COLLECTED BY DOTS FACILITATOR'
     ActiveDistrictModel.push(value);
    }
 },
 this.TTAdashboardData=ActiveDistrictModel
 );
 this.TTADefdashboardData.forEach(function (value) {
  if(value.id==3)
  {
    value.patientStatus='SAMPLEs RECEIVED by TTA'
   ActiveDistrictModel.push(value);
  }
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
if(value.id==4)
{
  value.patientStatus='Samples Transported by TTA'
 ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
if(value.id==5)
{
value.patientStatus='SAMPLES RECEIVED AT GENEXPERT LABS'
ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);

this.TTADefdashboardData.forEach(function (value) {
if(value.id==6)
{
  value.patientStatus='Results added at GeneXpert Labs'
ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
  if(value.id==8)
  {
    value.patientStatus='Registered at DOTS Clinic'
  ActiveDistrictModel.push(value);
  }
  },
  this.TTAdashboardData=ActiveDistrictModel
  );
this.TTADefdashboardData.forEach(function (value) {
if(value.id==7)
{
value.patientStatus='Referred to PMDT Site'
ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);

this.TTADefdashboardData.forEach(function (value) {
if(value.id==9)
{
value.patientStatus='Registered at PMDT Site'

ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
  if(value.id==10)
  {
    value.patientStatus='Samples sent to NRL'
  ActiveDistrictModel.push(value);
  }
  },
  this.TTAdashboardData=ActiveDistrictModel
  );
  this.TTADefdashboardData.forEach(function (value) {
    if(value.id==11)
    {
      value.patientStatus='Samples sent to PRL '
    ActiveDistrictModel.push(value);
    }
    },
    this.TTAdashboardData=ActiveDistrictModel
    );

      $('#districtresetto0').val('0');

    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
   }

    else if(this.FilterDashboardData.startDate > this.FilterDashboardData.endDate)
    {
      this.Toastr.error('Start date cannot be ahead of End Date'); 
    }

  }


    TehsilMultiData: { [key: string]: Object; }[] = [];
    public TehsilFields: Object = { text: 'tehsilName', value: 'id' };
    public TehsilPlaceholder: string = 'All Tehsils';

    GendersMultiData: { [key: string]: Object; }[] = [];
    public GendersFields: Object = { text: 'genderName', value: 'id' };
    public GendersPlaceholder: string = 'All Genders';
  
    // public value = [];

  SelectedDistrict(data){
    //  this.mulObj.value = [];
    this.FilterDashboardData.districtId = parseInt(data);
    this.dashboardService.getAllTehsils(this.FilterDashboardData.districtId).subscribe(data=>{
      this.TehsilData = data.body.tehsils;
      this.TehsildefMultiData = this.TehsilData;
      this.showTehsilsMultiDropDown = true;
      var ActiveDistrictModel = [];
      this.TehsildefMultiData.forEach(function (value) {
      if(value.isActive==true)
      {
       ActiveDistrictModel.push(value);
      }
   },
   this.TehsilMultiData=ActiveDistrictModel
   

   );     
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }


  selectedPatient(data) {
    this.allpatientsService.getPatientsDetailsByID(data.patientId).subscribe(data=>{
      this.showPatientDetails = true;
      this.IsShowSampleResults = false;
      this.SelectedPatientDetails = data.body.patient;
      if(data.body.patient.referenceLabSamples!=null){
        this.patientrefrencelabsample = data.body.patient.referenceLabSamples;
      }
      else{
        this.patientrefrencelabsample.labName=''
        this.patientrefrencelabsample.isReceived=''
        this.patientrefrencelabsample.nplabId=''
        this.patientrefrencelabsample.nplabSampleNumber=''
        this.patientrefrencelabsample.pmdtsampleNumber=''
        this.patientrefrencelabsample.referedToNrldate=''
        this.patientrefrencelabsample.referedToPrldate=''
        this.patientrefrencelabsample.sampleUpdatedDateAtNrl=''
        this.patientrefrencelabsample.sampleUpdatedDateAtPrl=''
        this.patientrefrencelabsample.isAccepted=''

      }     
    },error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }
  ShowSampleResults(data){
    this.SelectedSampleResultData = data;
    this.IsShowSampleResults = true;
  }
  
hidepatientform(){
  this.hideform=!this.hideform
}
selctedStatus(data){
 
}
getDashboardStatuspatients (data) {
  this.showPatientDetails = true;
  // this.FilterDashboardData.patientStatus=this.statusid
  if(localStorage.getItem("role") != null && localStorage.getItem("role")=='Admin'){}
  if(localStorage.getItem("role") != null && localStorage.getItem("role")=='DTC'){
    this.FilterDashboardData.districtId=localStorage.getItem("DTCDISTRICTID")
  }
  this.FilterDashboardData.patientStatus=data

  this.dashboardPatientsData = [];
  this.dashboardService.getDashboardPatients(this.FilterDashboardData).subscribe(data=>{
    this.dashboardPatientsData = data.body.patients;
  },
  error=>{
    this.Toastr.error("Something went on the server side !!!");
    console.log(error);
  });

}
export() {
  this.exportService.exportExcel(this.dashboardPatientsData, 'PatientsReport');

}
}
