
import { Component,  OnInit ,Output,EventEmitter } from '@angular/core';
import { Stat } from '../Model/stat.model';
import { DashboardServiceService } from '../services/dashboard-service.service';
import { DatePipe } from '@angular/common';
import { GenericServiceService } from '../services/generic-service.service';
import {FOrDTC, DashboardData,GetDashboardDataDefault } from '../Model/DashboardModel';
import * as Chart from 'chart.js';
import { ToastrService } from 'ngx-toastr';
/////

import { Router} from '@angular/router';

import { PatientstatusService } from '../services/patientstatus.service';
import { AllpatientsService } from '../services/allpatients.service';
import { GetAllPatientsModel,refrenacesamplesModel } from '../Model/PatientModel';
import { ExportService } from '../services/export.service';
declare var $: any;
@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.css']
})
export class StatComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  DALS:Stat=new Stat()
  DistrictsData: any;
  DTC: boolean;
  FilterState:any="Dropdown";
  FilterDistrict:any=0;
  FilterTehsil:any=0;
  FilterGender:any=0;
  FilterDashboardData : GetDashboardDataDefault = new GetDashboardDataDefault();
  constructor(private exportService:ExportService, private allpatientsService:AllpatientsService,private __dashboardService:DashboardServiceService,private genericService:GenericServiceService,private Toastr: ToastrService,public datepipe: DatePipe,) { }
  TTADefdashboardData: Array<any> = new Array();
  TTAdashboardData: any;
  TTATotalPatients:number
  graphpatientStatus:any
  graphdata:any
  ////
  GetDashboardDataDefault : GetDashboardDataDefault = new GetDashboardDataDefault();
  dashboardData: DashboardData = new DashboardData();
  dashboardPatientsData : Array<any> = new Array();
  showPatient : boolean = false;
  showPatientDetails : boolean = false;
  IsShowSampleResults:boolean = false;
  SelectedSampleResultData:boolean=false

  SelectedPatientDetails: GetAllPatientsModel = new GetAllPatientsModel();
  selectedPatientType : string = "";

  rcTargetModel : Array<any> = new Array();

  TehsilData : Array<any> = new Array();
  GenderData : Array<any> = new Array();

  FilterDashboardDataforDTC:FOrDTC=new FOrDTC()
  showTehsilsMultiDropDown : boolean = false;
  InitialStartDate: Date = new Date;
  InitialEndDate:  Date = new Date;

  statusid:number
  DistrictdID:any
  did:number
  chart:any;
  filterFlag:Boolean;
  patientrefrencelabsample: refrenacesamplesModel=new refrenacesamplesModel();

  hideform:boolean=false
  DistrictdefData: Array<any> = new Array();
  TehsildefMultiData: Array<any> = new Array();
  MonthName:string;
  isAdmin : boolean = false;
  ngOnInit() {
    this.headerTitle.emit("Dashboard");
    // this.__dashboardService.GetOAD().subscribe(res=>{
    //   this.DALS=res.body.stats

    // })
    this.MonthName=new Date().toLocaleString('en-us',{month:'short', year:'numeric'});

    if(localStorage.getItem("role") != null && localStorage.getItem("role")=='Admin' || localStorage.getItem("role") != null && localStorage.getItem("role")=='PM'){
      this.isAdmin=true
      this.genericService.getAllDistrictData().subscribe(data=>{
        this.DistrictdefData = data.body.districts;
        var ActiveDistrictModel = [];
        this.DistrictdefData.forEach(function (value) {
        if(value.isActive==true)
        {
         ActiveDistrictModel.push(value);
        }
     },
     this.DistrictsData=ActiveDistrictModel
     );
      },
      error=>{
        console.log(error);
      });
      this.FilterDashboardData.startDate = "";
    this.FilterDashboardData.endDate = "";
    //this.CurrentState("2021-09-01","2021-09-30")
    var start1=new Date();
    var mon=start1.getMonth()+1;
    var date=start1.getDate();
    var end1=new Date;
    localStorage.setItem("filterstartdate","2021-"+(mon<10?"0":"")+String(mon)+"-01 00:00:00")
    localStorage.setItem("filterenddate","2021-"+(mon<10?"0":"")+String(mon)+"-"+(date<10?"0":"")+String(date)+" 23:59:59.983")
    localStorage.setItem("userType","Admin")
   this.CurrentState("2021-"+(mon<10?"0":"")+String(mon)+"-01 00:00:00","2021-"+(mon<10?"0":"")+String(mon)+"-"+(date<10?"0":"")+String(date)+" 23:59:59.983","Admin")

    }

    if(localStorage.getItem("role") != null && localStorage.getItem("role")=='DTC'){
      this.DTC=true
      this.genericService.dtc().subscribe(data=>{
        this.DistrictsData=data.body.dtc
        localStorage.setItem("DTCDISTRICTID",this.DistrictsData[0].id)
        this.SelectedDistrict(this.DistrictsData[0].id)
      })
      this.FilterDashboardData.startDate = "";
      this.FilterDashboardData.endDate = "";
      //this.CurrentState("2021-09-01","2021-09-30")
      var start1=new Date();
      var mon=start1.getMonth()+1;
      var date=start1.getDate();
      var end1=new Date;
      localStorage.setItem("filterstartdate","2021-"+(mon<10?"0":"")+String(mon)+"-01 00:00:00")
      localStorage.setItem("filterenddate","2021-"+(mon<10?"0":"")+String(mon)+"-"+(date<10?"0":"")+String(date)+" 23:59:59.983")
      localStorage.setItem("userType","DTC")
     this.CurrentState("2021-"+(mon<10?"0":"")+String(mon)+"-01 00:00:00","2021-"+(mon<10?"0":"")+String(mon)+"-"+(date<10?"0":"")+String(date)+" 23:59:59.983","DTC")

  }
  this.__dashboardService.getGenderData().subscribe(data=>{
    this.GenderData = data.body.genders;
    this.GendersMultiData = this.GenderData;
  },
  error=>{
    this.Toastr.error("Something went on the server side !!!");
    console.log(error);
  });


  }
//   ShowStat(id)
//   {
//     if(id==4){
//       this.CurrentState("0","0")
//       localStorage.setItem("filterstartdate","0")
//       localStorage.setItem("filterenddate","0")
//     }
//     else if(id==1){
//       var start1=new Date();
//       var mon=start1.getMonth()+1;
//       var date=start1.getDate();
//       var end1=new Date;
//       localStorage.setItem("filterstartdate","2021-"+(mon<10?"0":"")+String(mon)+"-01")
//       localStorage.setItem("filterenddate","2021-09-"+(mon<10?"0":"")+String(date))
//      this.CurrentState("2021-"+(mon<10?"0":"")+String(mon)+"-01","2021-09-"+(mon<10?"0":"")+String(date))
//     }
//     else if(id==2){
//      var end = this.datepipe.transform(new Date, 'yyyy-MM-dd');
//       var  start= this.datepipe.transform((new Date).setMonth((new Date).getMonth()-3), 'yyyy-MM-dd');
// console.log(start)
// console.log(end)
// localStorage.setItem("filterstartdate",start)
// localStorage.setItem("filterenddate",end)
//     this.CurrentState(start,end)
//     }
//     else if(id==3){
//       var  end= this.datepipe.transform(new Date, 'yyyy-MM-dd');
//       var start = this.datepipe.transform((new Date).setMonth((new Date).getMonth()-12), 'yyyy-MM-dd');
// console.log(start)
// console.log(end)
// localStorage.setItem("filterstartdate",start)
// localStorage.setItem("filterenddate",end)
//     this.CurrentState(start,end)

//     }
//     console.log(id)
//   }
ShowStat(id)

{
  this.FilterState="Dropdown";
  var user=localStorage.getItem("userType");

  if(id>0){
    console.log(id)
    if(this.filterFlag==true){
      document.getElementById('filteractionbtn').innerHTML='  <i class="fa fa-search u-mr-xsmall"></i> Filter';
      this.reset()
      this.filterFlag=false;

    }
      if(id==4){
   // if(id != null){
     localStorage.setItem("filterstartdate","0")
     localStorage.setItem("filterenddate","0")
     this.CurrentState("0","0",user)


    // }
    // else{
    //   document.getElementById('filteractionbtn').innerHTML='  <i class="fa fa-search u-mr-xsmall"></i> Applied';
    // }


  }
  else if(id==1){
   // if(id != null){
    var start1=new Date();
    var mon=start1.getMonth()+1;
    var date=start1.getDate();
    var end1=new Date;
    localStorage.setItem("filterstartdate","2021-"+(mon<10?"0":"")+String(mon)+"-01 00:00:00")
    localStorage.setItem("filterenddate","2021-"+String(mon)+"-"+(date<10?"0":"")+String(date)+" 23:59:59.983")
   this.CurrentState("2021-"+(mon<10?"0":"")+String(mon)+"-01 00:00:00","2021-"+(mon<10?"0":"")+String(mon)+"-"+(date<10?"0":"")+String(date)+" 23:59:59.983",user)
   //document.getElementById('filteractionbtn').innerHTML='  <i class="fa fa-search u-mr-xsmall"></i> Filter';
 // }
 // else{
//    document.getElementById('filteractionbtn').innerHTML='  <i class="fa fa-search u-mr-xsmall"></i> Applied';
//  }
  }
  else if(id==2){
   // if(id != null){
   var end = this.datepipe.transform(new Date, 'yyyy-MM-dd');
    var  start= this.datepipe.transform((new Date).setMonth((new Date).getMonth()-3), 'yyyy-MM-dd');
console.log(start)
console.log(end)
localStorage.setItem("filterstartdate",start+" 00:00:00")
localStorage.setItem("filterenddate",end+" 23:59:59.983")
  this.CurrentState(start+" 00:00:00",end+" 23:59:59.983",user)
 // document.getElementById('filteractionbtn').innerHTML='  <i class="fa fa-search u-mr-xsmall"></i> Filter';

 // }
 // else{
//    document.getElementById('filteractionbtn').innerHTML='  <i class="fa fa-search u-mr-xsmall"></i> Applied';
//  }
}

  else if(id==3){
  //  if(id != null){
    var  end= this.datepipe.transform(new Date, 'yyyy-MM-dd');
    var start = this.datepipe.transform((new Date).setMonth((new Date).getMonth()-12), 'yyyy-MM-dd');
console.log(start)
console.log(end)
localStorage.setItem("filterstartdate",start+" 00:00:00")
localStorage.setItem("filterenddate",end+" 23:59:59.983")
  this.CurrentState(start+" 00:00:00",end+" 23:59:59.983",user)
  //document.getElementById('filteractionbtn').innerHTML='  <i class="fa fa-search u-mr-xsmall"></i> Filter';

 // }
 // else{
 //   document.getElementById('filteractionbtn').innerHTML='  <i class="fa fa-search u-mr-xsmall"></i> Applied';
 // }
}

  }


  console.log(id)

}
  CurrentState(start:string,end:string,user)
  {
    this.FilterDashboardData.startDate = start;
    this.FilterDashboardData.endDate = end;

    this.__dashboardService.GetOAByDate(start,end,user).subscribe(data=>{
      console.log(data)
      this.TTADefdashboardData = data.result.body.patientStatus;
       this.TTATotalPatients = data.result.body.totalPatients
      var ActiveDistrictModel = [];
        this.TTADefdashboardData.forEach(function (value) {
        if(value.id==1)
        {
          value.patientStatus='Registered Patients By DOTS FACILITATOR'
         ActiveDistrictModel.push(value);
        }
     },
     this.TTAdashboardData=ActiveDistrictModel
     );
     this.TTADefdashboardData.forEach(function (value) {
      if(value.id==2)
      {
        value.patientStatus='Samples collected by DOTS Facilitator'
       ActiveDistrictModel.push(value);
      }
   },
   this.TTAdashboardData=ActiveDistrictModel
   );
   this.TTADefdashboardData.forEach(function (value) {
    if(value.id==3)
    {
      value.patientStatus='SAMPLEs RECEIVED by TTA'
     ActiveDistrictModel.push(value);
    }
 },
 this.TTAdashboardData=ActiveDistrictModel
 );
 this.TTADefdashboardData.forEach(function (value) {
  if(value.id==4)
  {
    value.patientStatus=' Samples Transported by TTA'
   ActiveDistrictModel.push(value);
  }
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
  if(value.id==12)
  {
    value.patientStatus=' Samples Submitted By DOTS Facilitator'
   ActiveDistrictModel.push(value);
  }
},

this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
if(value.id==5)
{
  value.patientStatus='Samples Received at GeneXpert Labs'
 ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
if(value.id==6)
{
  value.patientStatus='Results added at GeneXpert Labs'
 ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);


this.TTADefdashboardData.forEach(function (value) {
if(value.id==8)
{
  value.patientStatus='Registered at DOTS Clinic'
 ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
if(value.id==7)
{
  value.patientStatus='Referred to PMDT Site'
 ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
if(value.id==9)
{
  value.patientStatus='Registered at PMDT Site'

 ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
if(value.id==10 )
{
value.patientStatus='Samples sent to NRL'
 ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
if(value.id==11 )
{
  value.patientStatus='Samples sent to PRL'
 ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);
var ActiveDistrictModelofpatientStatus = [];

this.TTADefdashboardData.forEach(function (value) {
if(value.patientStatus )
{
  ActiveDistrictModelofpatientStatus.push(value.patientStatus);
}
},
this.graphpatientStatus=ActiveDistrictModelofpatientStatus,
);
var ActiveDistrictModels = [];

this.TTADefdashboardData.forEach(function (value) {
if(value.patients )
{
  ActiveDistrictModels.push(value.patients);
}
},
this.graphdata=ActiveDistrictModels,
);
var garphd=[]
var graphstat=[]
this.TTADefdashboardData.map(a=>
{
//  graphdata2: any;
//  graphpatientStatus2: any;
garphd.push(a.patients)
graphstat.push(a.patientStatus)
})
graphstat[0]="Registered Patients By DOTS Facilitator"
graphstat[2]="Samples Recieved By TTA"
graphstat[1]="Samples Collected By DOTS Facilitator"
console.log(graphstat)
console.log(garphd)
//////////////////

// if (myChart) {
//   myChart.destroy();
// }
const canvas = <HTMLCanvasElement> document.getElementById('myChart');
const ctx = canvas.getContext('2d');
var myChart = new Chart(ctx, {
  type: 'pie',
  data: {
      labels: [graphstat[0],graphstat[1],graphstat[2],graphstat[3],graphstat[4],graphstat[5],graphstat[6],graphstat[7],graphstat[8],graphstat[9],graphstat[10],graphstat[11]
    ],
      datasets: [{
          label: 'No. of Patients',

          data: [garphd[0],garphd[1],garphd[2],garphd[3],garphd[4],garphd[5],garphd[6],garphd[7],garphd[8],garphd[9],garphd[10],garphd[11]],
          backgroundColor: [
              'rgba(255, 0, 0, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(128, 0, 0, 1)',
              'rgba(128, 128, 0, 1)',
              'rgba(0, 0, 128, 1)',
              'rgba(128, 0, 128, 1)',
              'rgba(0, 128, 128, 1)',
          ],

      }]
  },
  // options: {
  //   legend: {
  //     display: false,
  //   },
  //   scales: {
  //     yAxes: [{
  //       ticks: {
  //         beginAtZero: true,
  //         stepSize: 1, // I'm getting an error here
  //       },
  //     }],
  //   },
  // },
});

// if (myChart2) {
//   myChart2.destroy();
// }
const canvass = <HTMLCanvasElement> document.getElementById('myChart2');
const ctxx = canvass.getContext('2d');
var myChart2 = new Chart(ctxx, {
  type: 'line',
  data: {
    labels: [graphstat[0],graphstat[1],graphstat[2],graphstat[3],graphstat[4],graphstat[5],graphstat[6],graphstat[7],graphstat[8],graphstat[9],graphstat[10],graphstat[11]
  ],
      datasets: [{
          label: 'No. of Patients',
          data: [garphd[0],garphd[1],garphd[2],garphd[3],garphd[4],garphd[5],garphd[6],garphd[7],garphd[8],garphd[9],garphd[10],garphd[11]],
          backgroundColor: [
              'rgba(255, 0, 0, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
              'rgba(128, 0, 0, 1)',
              'rgba(128, 128, 0, 1)',
              'rgba(0, 0, 128, 1)',
              'rgba(128, 0, 128, 1)',
              'rgba(0, 128, 128, 1)',
          ],

      }]
  },
});


//3rd chart compare
// if (myChart3) {
//   myChart3.destroy();
// }
const canvasss = <HTMLCanvasElement> document.getElementById('myChart3');
const ctxxx = canvasss.getContext('2d');
var myChart3 = new Chart(ctxxx, {
  type: 'bar',

  data: {
      labels: [graphstat[0],graphstat[7],graphstat[8],
    ],

      datasets: [{
          label: 'No. of Patients',

          data: [garphd[0],this.graphdata[7],garphd[8],],

          backgroundColor: [
              'rgba(255, 0, 0, 1)',
              //'rgba(54, 162, 235, 1)',
               //'rgba(255, 206, 86, 1)',
               //'rgba(75, 192, 192, 1)',
               //'rgba(153, 102, 255, 1)',
              // 'rgba(255, 159, 64, 1)',
              // 'rgba(128, 0, 0, 1)',
              // 'rgba(128, 128, 0, 1)',
               'rgba(0, 0, 128, 1)',
               'rgba(128, 0, 128, 1)',
              // 'rgba(0, 128, 128, 1)',
          ],

      }]

  },
});

// if (myChart4) {
//   myChart4.destroy();
// }
const canvassss = <HTMLCanvasElement> document.getElementById('myChart4');
const ctxxxx = canvassss.getContext('2d');
var myChart4 = new Chart(ctxxxx, {
  type: 'bar',

  data: {
      labels: [graphstat[2],graphstat[3],
    ],

      datasets: [{
          label: 'No. of Patients',

          data: [garphd[2],garphd[3],],

          backgroundColor: [
              'rgba(255, 0, 0, 1)',
              //'rgba(54, 162, 235, 1)',
               //'rgba(255, 206, 86, 1)',
               //'rgba(75, 192, 192, 1)',
               //'rgba(153, 102, 255, 1)',
              // 'rgba(255, 159, 64, 1)',
              // 'rgba(128, 0, 0, 1)',
              // 'rgba(128, 128, 0, 1)',
               'rgba(0, 0, 128, 1)',
              // 'rgba(128, 0, 128, 1)',
              // 'rgba(0, 128, 128, 1)',
          ],

      }]

  },
});




},

    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }
  onSubmit(form: any): void {
    this.FilterState="Filter";
    this.filterFlag=true;
    //@ts-ignore
    document.getElementById('filterbtn').disabled=true;
    this.FilterDashboardData.tehsilIds = '0';
    this.FilterDashboardData.genderIds = '0';
    if(form.value.TehsilData !== undefined){
      if(form.value.TehsilData.length !== 0){
        this.FilterDashboardData.tehsilIds = form.value.TehsilData.toString();
        this.FilterTehsil= form.value.TehsilData.toString();
        console.log(form.value.TehsilData.toString());
      }
    }
  if(form.value.GendersData !== undefined){
    if(form.value.GendersData.length !== 0){
      this.FilterDashboardData.genderIds = form.value.GendersData.toString();
      this.FilterGender=form.value.GendersData.toString();
    }
  }

  this.FilterDashboardData.startDate = this.datepipe.transform(this.InitialStartDate, 'yyyy-MM-dd')+" 00:00:00";
  this.FilterDashboardData.endDate = this.datepipe.transform(this.InitialEndDate, 'yyyy-MM-dd')+" 23:59:59.983";
  localStorage.setItem("filterstartdate",this.FilterDashboardData.startDate)
  localStorage.setItem("filterenddate", this.FilterDashboardData.endDate)

   if(this.FilterDashboardData.startDate <= this.FilterDashboardData.endDate){
     this.__dashboardService.GetOAByDateTehsilDistrict(this.FilterDashboardData).subscribe(data=>{
      $("#DashBoardFilter").modal("hide");
      this.TTADefdashboardData = data.result.body.patientStatus;
      this.TTATotalPatients = data.result.body.totalPatients
      var ActiveDistrictModel = [];
      this.TTADefdashboardData.forEach(function (value) {
      if(value.id==1)
      {
        value.patientStatus='Registered Patients By DOTS FACILITATOR'
       ActiveDistrictModel.push(value);
      }
   },
   this.TTAdashboardData=ActiveDistrictModel
   );
   this.TTADefdashboardData.forEach(function (value) {
    if(value.id==2)
    {
      value.patientStatus='SAMPLES COLLECTED BY DOTS FACILITATOR'
     ActiveDistrictModel.push(value);
    }
 },
 this.TTAdashboardData=ActiveDistrictModel
 );
 this.TTADefdashboardData.forEach(function (value) {
  if(value.id==3)
  {
    value.patientStatus='SAMPLEs RECEIVED by TTA'
   ActiveDistrictModel.push(value);
  }
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
if(value.id==4)
{
  value.patientStatus='Samples Transported by TTA'
 ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
  if(value.id==12)
  {
    value.patientStatus=' Samples Submitted By DOTS Facilitator'
   ActiveDistrictModel.push(value);
  }
},

this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
if(value.id==5)
{
value.patientStatus='SAMPLES RECEIVED AT GENEXPERT LABS'
ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);

this.TTADefdashboardData.forEach(function (value) {
if(value.id==6)
{
  value.patientStatus='Results added at GeneXpert Labs'
ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
  if(value.id==8)
  {
    value.patientStatus='Registered at DOTS Clinic'
  ActiveDistrictModel.push(value);
  }
  },
  this.TTAdashboardData=ActiveDistrictModel
  );
this.TTADefdashboardData.forEach(function (value) {
if(value.id==7)
{
value.patientStatus='Referred to PMDT Site'
ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);

this.TTADefdashboardData.forEach(function (value) {
if(value.id==9)
{
value.patientStatus='Registered at PMDT Site'

ActiveDistrictModel.push(value);
}
},
this.TTAdashboardData=ActiveDistrictModel
);
this.TTADefdashboardData.forEach(function (value) {
  if(value.id==10)
  {
    value.patientStatus='Samples sent to NRL'
  ActiveDistrictModel.push(value);
  }
  },
  this.TTAdashboardData=ActiveDistrictModel
  );
  this.TTADefdashboardData.forEach(function (value) {
    if(value.id==11)
    {
      value.patientStatus='Samples sent to PRL '
    ActiveDistrictModel.push(value);
    }
    },
    this.TTAdashboardData=ActiveDistrictModel
    );
    var ActiveDistrictModelofpatientStatus = [];

this.TTADefdashboardData.forEach(function (value) {
if(value.patientStatus )
{
  ActiveDistrictModelofpatientStatus.push(value.patientStatus);
}
},
this.graphpatientStatus=ActiveDistrictModelofpatientStatus,
);
var ActiveDistrictModels = [];

this.TTADefdashboardData.forEach(function (value) {
if(value.patients )
{
  ActiveDistrictModels.push(value.patients);
}
},
this.graphdata=ActiveDistrictModels,
);

      $('#districtresetto0').val('0');
      var garphd=[]
      var graphstat=[]
      this.TTADefdashboardData.map(a=>
      {
      //  graphdata2: any;
      //  graphpatientStatus2: any;
      garphd.push(a.patients)
      graphstat.push(a.patientStatus)
      })
      graphstat[0]="Registered Patients By DOTS Facilitator"
      graphstat[2]="Samples Recieved By TTA"
      graphstat[1]="Samples Collected By DOTS Facilitator"
      console.log(graphstat)
      console.log(garphd)
      //////////////////

      // if (myChart) {
      //   myChart.destroy();
      // }
      const canvas = <HTMLCanvasElement> document.getElementById('myChart');
      const ctx = canvas.getContext('2d');
      var myChart = new Chart(ctx, {
        type: 'pie',
        data: {
            labels: [graphstat[0],graphstat[1],graphstat[2],graphstat[3],graphstat[4],graphstat[5],graphstat[6],graphstat[7],graphstat[8],graphstat[9],graphstat[10],graphstat[11]
          ],
            datasets: [{
                label: 'No. of Patients',

                data: [garphd[0],garphd[1],garphd[2],garphd[3],garphd[4],garphd[5],garphd[6],garphd[7],garphd[8],garphd[9],garphd[10],garphd[11]],
                backgroundColor: [
                    'rgba(255, 0, 0, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(128, 0, 0, 1)',
                    'rgba(128, 128, 0, 1)',
                    'rgba(0, 0, 128, 1)',
                    'rgba(128, 0, 128, 1)',
                    'rgba(0, 128, 128, 1)',
                ],

            }]
        },
        // options: {
        //   legend: {
        //     display: false,
        //   },
        //   scales: {
        //     yAxes: [{
        //       ticks: {
        //         beginAtZero: true,
        //         stepSize: 1, // I'm getting an error here
        //       },
        //     }],
        //   },
        // },
      });

      // if (myChart2) {
      //   myChart2.destroy();
      // }
      const canvass = <HTMLCanvasElement> document.getElementById('myChart2');
      const ctxx = canvass.getContext('2d');
      var myChart2 = new Chart(ctxx, {
        type: 'line',
        data: {
          labels: [graphstat[0],graphstat[1],graphstat[2],graphstat[3],graphstat[4],graphstat[5],graphstat[6],graphstat[7],graphstat[8],graphstat[9],graphstat[10],graphstat[11]
        ],
            datasets: [{
                label: 'No. of Patients',
                data: [garphd[0],garphd[1],garphd[2],garphd[3],garphd[4],garphd[5],garphd[6],garphd[7],garphd[8],garphd[9],garphd[10],garphd[11]],
                backgroundColor: [
                    'rgba(255, 0, 0, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(128, 0, 0, 1)',
                    'rgba(128, 128, 0, 1)',
                    'rgba(0, 0, 128, 1)',
                    'rgba(128, 0, 128, 1)',
                    'rgba(0, 128, 128, 1)',
                ],

            }]
        },
      });


      //3rd chart compare
      // if (myChart3) {
      //   myChart3.destroy();
      // }
      const canvasss = <HTMLCanvasElement> document.getElementById('myChart3');
      const ctxxx = canvasss.getContext('2d');
      var myChart3 = new Chart(ctxxx, {
        type: 'bar',

        data: {
            labels: [graphstat[0],graphstat[7],graphstat[8],
          ],

            datasets: [{
                label: 'No. of Patients',

                data: [garphd[0],garphd[7],garphd[8],],

                backgroundColor: [
                    'rgba(255, 0, 0, 1)',
                    //'rgba(54, 162, 235, 1)',
                     //'rgba(255, 206, 86, 1)',
                     //'rgba(75, 192, 192, 1)',
                     //'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                    // 'rgba(128, 0, 0, 1)',
                    // 'rgba(128, 128, 0, 1)',
                     'rgba(0, 0, 128, 1)',
                     'rgba(128, 0, 128, 1)',
                    // 'rgba(0, 128, 128, 1)',
                ],

            }]

        },
      });

      // if (myChart4) {
      //   myChart4.destroy();
      // }
      const canvassss = <HTMLCanvasElement> document.getElementById('myChart4');
      const ctxxxx = canvassss.getContext('2d');
      var myChart4 = new Chart(ctxxxx, {
        type: 'bar',

        data: {
            labels: [graphstat[2],graphstat[3],
          ],

            datasets: [{
                label: 'No. of Patients',

                data: [garphd[2],garphd[3],],

                backgroundColor: [
                    'rgba(255, 0, 0, 1)',
                    //'rgba(54, 162, 235, 1)',
                     //'rgba(255, 206, 86, 1)',
                     //'rgba(75, 192, 192, 1)',
                     //'rgba(153, 102, 255, 1)',
                    // 'rgba(255, 159, 64, 1)',
                    // 'rgba(128, 0, 0, 1)',
                    // 'rgba(128, 128, 0, 1)',
                     'rgba(0, 0, 128, 1)',
                    // 'rgba(128, 0, 128, 1)',
                    // 'rgba(0, 128, 128, 1)',
                ],

            }]

        },
      });
document.getElementById('filterbtn').innerText="Applied"
document.getElementById('filterbtn').style.background='grey'
document.getElementById('filterbtn').style.borderColor='grey'
//document.getElementById('filterbtn')[DISABLED] = true;
document.getElementById('filteractionbtn').innerHTML=' <i class="fa fa-search u-mr-xsmall"></i> Applied';

    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
   }

    else if(this.FilterDashboardData.startDate > this.FilterDashboardData.endDate)
    {
      this.Toastr.error('Start date cannot be ahead of End Date');
    }

  }


    TehsilMultiData: { [key: string]: Object; }[] = [];
    public TehsilFields: Object = { text: 'tehsilName', value: 'id' };
    public TehsilPlaceholder: string = 'All Tehsils';

    GendersMultiData: { [key: string]: Object; }[] = [];
    public GendersFields: Object = { text: 'genderName', value: 'id' };
    public GendersPlaceholder: string = 'All Genders';

    // public value = [];

  SelectedDistrict(data){
    //  this.mulObj.value = [];
    this.FilterDistrict=parseInt(data);
    this.FilterDashboardData.districtId = parseInt(data);
    this.__dashboardService.getAllTehsils(this.FilterDashboardData.districtId).subscribe(data=>{
      this.TehsilData = data.body.tehsils;
      this.TehsildefMultiData = this.TehsilData;
      this.showTehsilsMultiDropDown = true;
      var ActiveDistrictModel = [];
      this.TehsildefMultiData.forEach(function (value) {
      if(value.isActive==true)
      {
       ActiveDistrictModel.push(value);
      }
   },
   this.TehsilMultiData=ActiveDistrictModel


   );
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }


  selectedPatient(data) {
    this.allpatientsService.getPatientsDetailsByID(data.patientId).subscribe(data=>{
      this.showPatientDetails = true;
      this.IsShowSampleResults = false;
      this.SelectedPatientDetails = data.body.patient;
      if(data.body.patient.referenceLabSamples!=null){
        this.patientrefrencelabsample = data.body.patient.referenceLabSamples;
      }
      else{
        this.patientrefrencelabsample.labName=''
        this.patientrefrencelabsample.isReceived=''
        this.patientrefrencelabsample.nplabId=''
        this.patientrefrencelabsample.nplabSampleNumber=''
        this.patientrefrencelabsample.pmdtsampleNumber=''
        this.patientrefrencelabsample.referedToNrldate=''
        this.patientrefrencelabsample.referedToPrldate=''
        this.patientrefrencelabsample.sampleUpdatedDateAtNrl=''
        this.patientrefrencelabsample.sampleUpdatedDateAtPrl=''
        this.patientrefrencelabsample.isAccepted=''

      }
    },error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }
  ShowSampleResults(data){
    this.SelectedSampleResultData = data;
    this.IsShowSampleResults = true;
  }

hidepatientform(){
  this.hideform=!this.hideform
}
selctedStatus(data){

}
getDashboardStatuspatients (data,count) {
  if(Number(count)>0)
  {
     this.showPatientDetails = true;
  // this.FilterDashboardData.patientStatus=this.statusid
  if(localStorage.getItem("role") != null && localStorage.getItem("role")=='Admin'){}
  if(localStorage.getItem("role") != null && localStorage.getItem("role")=='DTC'){
    this.FilterDashboardData.districtId=localStorage.getItem("DTCDISTRICTID")
  }
  this.FilterDashboardData.patientStatus=data

  this.dashboardPatientsData = [];
  this.__dashboardService.getDashboardPatientsOverall(data,localStorage.getItem("filterstartdate"),localStorage.getItem("filterenddate"),{FilterState:this.FilterState,District:this.FilterDistrict,Tehsil:this.FilterTehsil,Gender:this.FilterGender}).subscribe(data=>{
    this.dashboardPatientsData = data.body.patients;
  },
  error=>{
    this.Toastr.error("Something went on the server side !!!");
    console.log(error);
  });
  }
  else{
    this.dashboardPatientsData =[];
  }


}
export() {
  this.exportService.exportExcel(this.dashboardPatientsData, 'PatientsReport');

}
async reset(){
  if(this.DTC){

    this.SelectedDistrict(localStorage.getItem("DTCDISTRICTID"));
    //@ts-ignore
    document.getElementById('districtresetto0').value=localStorage.getItem("DTCDISTRICTID")

  }
 this.FilterDistrict=0;this.FilterTehsil=0;this.FilterGender=0
this.FilterDashboardData=new GetDashboardDataDefault()
  this.filterFlag=false;
  this.InitialStartDate = new Date();
  this.InitialEndDate=new Date();
  document.getElementById('filteractionbtn').innerHTML='  <i class="fa fa-search u-mr-xsmall"></i> Filter';
  document.getElementById('filterbtn').style.backgroundColor ='green'
  document.getElementById('filterbtn').innerText ='Apply'
  this.showTehsilsMultiDropDown=false;
  // document.getElementById("Mobility").selectedIndex = 12;
  //@ts-ignore
  var value=document.getElementById('District').value;
    //@ts-ignore
    document.getElementById('filterbtn').disabled=false;

    if(localStorage.getItem("role") != null && localStorage.getItem("role")=='Admin' || localStorage.getItem("role") != null && localStorage.getItem("role")=='PM'){
      this.isAdmin=true
      this.genericService.getAllDistrictData().subscribe(data=>{
        this.DistrictdefData = data.body.districts;
        var ActiveDistrictModel = [];
        this.DistrictdefData.forEach(function (value) {
        if(value.isActive==true)
        {
         ActiveDistrictModel.push(value);
        }
     },
     this.DistrictsData=ActiveDistrictModel
     );
      },
      error=>{
        console.log(error);
      });
      this.FilterDashboardData.startDate = "";
    this.FilterDashboardData.endDate = "";

    }
    this.__dashboardService.getGenderData().subscribe(data=>{
      this.GenderData = data.body.genders;
      this.GendersMultiData = this.GenderData;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  //this.ngOnInit()
  //this.ShowStat(Number(value));
}
}
