import { Component,  OnInit ,Output,EventEmitter } from '@angular/core';
import { GenePatientsModelEdit } from '../Model/PatientModel';
import { GenericServiceService } from '../services/generic-service.service';
import { AllpatientsService } from '../services/allpatients.service';
import { ExportService } from '../services/export.service';
import { ToastrService } from 'ngx-toastr';
import { GetDashboardDataDefault } from '../Model/DashboardModel';
import { DatePipe } from '@angular/common';
import { DashboardServiceService } from '../services/dashboard-service.service';

declare var $: any;
@Component({
  selector: 'app-gene-patients',

  templateUrl: './gene-patients.component.html',
  styleUrls: ['./gene-patients.component.css']
})
export class GenePatientsComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  GexperData:any
  p:any
  patientName: any
  genderId: any
  genderName: any
  fatherHusbandName: any
  InitialStartDate: Date = new Date;
  InitialEndDate: Date = new Date;
  Genderdatas: any
  SampleResultOption:any;
  PatientRelations:any;
  RifampicinOptions:any;
  SeverityData:any;
  RRValueOptions:any
  PhoneRelation:any;
  psDataId: number
  SelectedSampleResultData: Array<any> = new Array();
  IsShowSampleResults: boolean = false;
  allPatient: Array<any> = new Array();
  GenderData: Array<any> = new Array();
  patient__Id: any
  FilterDashboardData: GetDashboardDataDefault = new GetDashboardDataDefault();
  SelectedPatienttoEditDetails: GenePatientsModelEdit = new GenePatientsModelEdit();
  constructor(private allpatientsService: AllpatientsService, private __patientService:AllpatientsService, private exportService:ExportService, public datepipe: DatePipe,  private dashboardService: DashboardServiceService,private Toastr: ToastrService, private genericService: GenericServiceService) { }


  ngOnInit() {
    this.headerTitle.emit("Other GenExpert Patients");
    this.__patientService.getGEP().subscribe(res=>{
      console.log(res)
      this.GexperData=res.body.patients.map(p=>{
        // p.resultAddedDate=new Date(p.resultAddedDate).toDateString()
        // p.sampleColledtedDate=new Date(p.sampleColledtedDate).toDateString();
        //   p.createdAt=new Date(p.createdAt).toDateString();
          return p;
      })

    })
    this.dashboardService.getGenderData().subscribe(data => {
      this.GenderData = data.body.genders;
      this.GendersMultiData = this.GenderData;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
      this.dashboardService.getGenderData().subscribe(data => {
        this.Genderdatas = data.body.genders;

      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          console.log(error);
        });
this.dashboardService.GetPatientRelations().subscribe(data => {
          this.PatientRelations = data.body.patientRelations;
          console.log(this.PatientRelations)

        },
          error => {
            this.Toastr.error("Something went on the server side !!!");
            console.log(error);
          });

        this.dashboardService.getResultOptionsData().subscribe(data => {
          this.SampleResultOption = data.body.resultOptions;
          console.log(this.SampleResultOption)

        },
          error => {
            this.Toastr.error("Something went on the server side !!!");
            console.log(error);
          });
          this.dashboardService.getSeverityData().subscribe(data => {
            this.SeverityData = data.body.sampleSaverity;
            console.log(this.SeverityData)

          },
            error => {
              this.Toastr.error("Something went on the server side !!!");
              console.log(error);
            });


 this.dashboardService.GetRifampicinOptions().subscribe(data => {
            this.RifampicinOptions = data.body.rifampicinOption;
            console.log(this.RifampicinOptions)

          },
            error => {
              this.Toastr.error("Something went on the server side !!!");
              console.log(error);
            });

            this.dashboardService.getRRValueOptionsData().subscribe(data => {
          this.RRValueOptions = data.body.rrValueOptions;
          console.log(this.RRValueOptions)

        },
          error => {
            this.Toastr.error("Something went on the server side !!!");
            console.log(error);
          });
  }
  export() {
    this.exportService.exportExcel(this.GexperData, 'OtherGenePatientsReport');

  }
  SelectedGenderForPatient(data) {
    this.SelectedPatienttoEditDetails.GenderId = data;
  }
  SelectedRelationName(data) {
    this.SelectedPatienttoEditDetails.NameRelation = data;
  }
   SelectedRRValue(data) {
    this.SelectedPatienttoEditDetails.RRValues = data;
  }
   SelectedRifampicinOptions(data) {
    this.SelectedPatienttoEditDetails.RifampicinOption = data;
  }
    SelectedSeverity(data) {
    this.SelectedPatienttoEditDetails.ResultSeverity = data;
  }
  SelectedPhoneRelation(data) {
    this.SelectedPatienttoEditDetails.PhoneRelation = data;
  }
  ShowSampleResults(data) {
    this.SelectedSampleResultData = data;
    this.IsShowSampleResults = true;
  }

  selectedPatienttoEdit(data) {
    console.log(data)
    this.SelectedPatienttoEditDetails.PatientName = data.patientName
    this.SelectedPatienttoEditDetails.PatientId = data.patientId
    this.SelectedPatienttoEditDetails.FatherHusbandName = data.fatherHusbandName
    this.SelectedPatienttoEditDetails.GenderId = data.genderId
    this.SelectedPatienttoEditDetails.CNIC = data.cnic
    this.SelectedPatienttoEditDetails.Phone = data.phone
    this.SelectedPatienttoEditDetails.TBNumber = data.tbnumber
    this.SelectedPatienttoEditDetails.DRTBNumber = data.drtbnumber
    this.SelectedPatienttoEditDetails.houseHoldScreened = data.houseHoldScreened == null ? 0 : data.houseHoldScreened
    this.SelectedPatienttoEditDetails.SampleCollectedDate=data.sampleColledtedDate
    this.SelectedPatienttoEditDetails.Cxrscreened = data.cxrscreened == null ? 0 : data.cxrscreened
    this.SelectedPatienttoEditDetails.SampleNumber = data.patientSampleNumber
this.SelectedPatienttoEditDetails.NameRelation=data.nameRelation
this.SelectedPatienttoEditDetails.createdAt=data.createdAt
this.SelectedPatienttoEditDetails.ResultAddedDate=data.resultAddedDate
this.SelectedPatienttoEditDetails.SampleNumber=data.patientSampleNumber
    // this.patientName=data.patientName
    //  this.fatherHusbandName=data.father_HusbandName
    //  this.genderId=data.genderId
      this.SelectedPatienttoEditDetails.genderName=data.genderName
      this.SelectedPatienttoEditDetails.Address=data.address
     // this.SelectedPatienttoEditDetails.PhoneRelation=data.phoenRelation
    //  this.SelectedPatienttoEditDetails.ResultSeverity=data.severity
    //  this.SelectedPatienttoEditDetails.NameRelation=data.relation
    //  this.SelectedPatienttoEditDetails.RifampicinOption=data.rifampicinOption
    //  this.SelectedPatienttoEditDetails.RRValues=data.rroption
    //  this.SelectedPatienttoEditDetails.PhoneRelation=data.phoenRelation
     this.SeverityData.map(r=>{if(r.severity==data.severity){this.SelectedPatienttoEditDetails.ResultSeverity=r.id}})
     this.PatientRelations.map(r=>{if(r.relation==data.nameRelation){this.SelectedPatienttoEditDetails.NameRelation=r.id} })

       this.RifampicinOptions.map(r=>{console.log(r.rifampicinOption,data.rifampicinOption);if(r.rifampicinOption==data.rifampicinOption){ this.SelectedPatienttoEditDetails.RifampicinOption=r.id;console.log( this.SelectedPatienttoEditDetails.RifampicinOption)}})
       this.RRValueOptions.map(r=>{if(r.rrOption==data.rroption){   this.SelectedPatienttoEditDetails.RRValues=r.id}})
       this.PatientRelations.map(r=>{if(r.relation==data.phoenRelation){this.SelectedPatienttoEditDetails.PhoneRelation=r.id} })

    //  this.cnic=data.cnic
    //  this.phone=data.phone
    //   this.tbNumber=data.tbNumber
    //   this.drtbnumber=data.drtbNumber
      // this.SelectedPatienttoEditDetails.houseHoldsScreend=data.houseHoldsScreend
    //   this.SelectedPatienttoEditDetails.cxrScreened=data.cxrScreened
    //   this.sampleNumber=data.sampleNumber
    //  this.patientId=data.patientId
    console.log(  this.SelectedPatienttoEditDetails)
  }
  selectedPatienttodelete(data) {
    console.log(data.patientId);
    this.patient__Id = data.patientId
    console.log(data)

  }
  deletePatient() {
    this.genericService.RemoveGeneExpertPatient(this.patient__Id).subscribe(res => {

      $("#delete__Patient").modal("hide");
      this.Toastr.success('Successfully Deleted');
      this.ngOnInit();
      location.reload()
    }, err => {
      this.Toastr.error('Error 302');
    });
  }

  GendersMultiData: { [key: string]: Object; }[] = [];
  public GendersFields: Object = { text: 'genderName', value: 'id' };
  public GendersPlaceholder: string = 'All Genders';
UpdatePatient() {
  this.allpatientsService.UpdateGeneExpert(
    // this.patientName,
    // this.fatherHusbandName,
    // this.SelectedPatienttoEditDetails.genderId,
    // this.cnic,
    // this.phone,
    // this.tbNumber,
    // this.drtbnumber,
    // this.houseHoldsScreend,
    // this.cxrScreened,
    // this.samplenumber,
    // this.patientId,
    this.SelectedPatienttoEditDetails
  ).subscribe(resp => {
    this.Toastr.success('Patient Updated Successfully ');
    $("#EditPatient").modal("hide");
    this.ngOnInit();
    // window.location.reload()
  }, err => {
    this.Toastr.error("Something went on the server side !!!");
    console.log(err);
  });
}}
