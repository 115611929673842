import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HealthFacility } from '../Model/HealthFacilityModel';
import { HealthfacilityService } from '../services/healthfacility.service';
import { GenericServiceService } from '../services/generic-service.service';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { LabsService } from '../services/labs.service';
declare var $: any;

@Component({
  selector: 'app-healthfacility',
  templateUrl: './healthfacility.component.html',
  styleUrls: ['./healthfacility.component.css']
})
export class HealthfacilityComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  HealthFacilityData: Array<HealthFacility>= new Array();
  GenderData: Array<any>= new Array();
  TehsilData: Array<any>= new Array();
  isAdmin : boolean = false;
  showselectedGender: boolean = false;
  public  GenderName: string;
  CreateHealthFacility : HealthFacility = new HealthFacility();
  SelectedHealthFacility: HealthFacility = new HealthFacility();
  filter:any;
  districtdata:Array<any>= new Array();


  constructor(private HealthfacilityService: HealthfacilityService,private disttehsil:LabsService, private genericService: GenericServiceService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService) { }

  ngOnInit() {
    this.headerTitle.emit("Health Facility");
    if(localStorage.getItem("role") != null && localStorage.getItem("role")=='Admin'){
      this.isAdmin=true
    }
    this.HealthfacilityService.GetHealthFacility(0).subscribe(data=>{
      this.HealthFacilityData = data.body.healthFacilities;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });

    // this.HealthfacilityService.GetAllTehsils().subscribe(data=>{
    //   this.TehsilData = data.body.tehsils;
    // },
    // error=>{
    //   this.Toastr.error("Something went on the server side !!!");
    //   console.log(error);
    // });
    this.genericService.getAllDistrictData().subscribe(res=>{
      this.districtdata=res.body.districts
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    })
  }
  ShowSelecteddistrictData(id){
    // this.Filtereddistrict = parseInt(id);

    this.disttehsil.getTTAtehsil(id).subscribe(data=>{
      this.TehsilData=data.body.tehsils
    },
     error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    }
     )
  }
  ShowSelectedTehsilTTaData(data){
    this.HealthFacilityData = [];
    this.HealthfacilityService.GetHealthFacility(data).subscribe(data=>{
      this.HealthFacilityData = data.body.healthFacilities;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }

  SelectedTehsil(data){this.CreateHealthFacility.tehsilId = parseInt(data);}

  SelectedTTADetail(data){
    this.SelectedHealthFacility = data;
  }

  Create(){
    this.HealthfacilityService.CreateHealthFacility(this.CreateHealthFacility).subscribe((resp)=>{
      this.Toastr.success('Health Facility Created Successfully ');
      $("#CreateHealthFacility").modal("hide");
      this.ngOnInit();
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
        console.log(error);
      });
  }

  Update(){
    this.HealthfacilityService.UpdateHealthFacility(this.SelectedHealthFacility).subscribe(resp=>{
      this.Toastr.success('Health Facility Updated Successfully ');
      $("#EditHealthFacilityModal").modal("hide");
      this.ngOnInit();
      console.log(resp);
    },error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });

  }

}

